import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SlActionRedo } from "react-icons/sl";
import axios from 'axios';
import { ApiData } from './CommonFile/ContextApi/Context';
import { Helmet } from 'react-helmet';

const TermsCondition = () => {
    const { domainName } = ApiData()
    const [termsData, setTermsData] = useState([]);


    try {
        useEffect(() => {
            const fetchData = async () => {
                const res = await axios.get(`${domainName}/view-terms-condition`)
                // console.log(res.data.records);
                setTermsData(res.data.records);

            }
            fetchData();
        }, [domainName])

    } catch (error) {
        console.log("Axiox Error", error)

    }
    // console.log(termsData)
    return (
        <>
            <Helmet>
                <title> {termsData && termsData.title ? termsData.seo_title : "Terms and Conditions - Saral Legal Pedia | Legal Information"}</title>
                <meta name="description" content={termsData && termsData.seo_description ? termsData.seo_description : "Terms and Conditions of Saral Legal Pedia to understand the rules and guidelines for using our legal information platform."} />

                <meta property="og:title" content={termsData.seo_title || "Terms And Condition"} />

                <meta name="keywords" content={termsData && termsData.seo_keyword ? termsData.seo_keyword : "Saral legal pedia,Legal guides,Easy legal advice,Legal advice platform,Legal information website,Legal resources for beginners,Privacy policy services,FAQs,   "} />

                <link rel="canonical" href={termsData && termsData.canonical_links ? termsData.canonical_links : "www.sarallegalpedia.com/terms-condition"} />
            </Helmet>


            {/* Bradcrum section start */}
            <div className="container-fluid p-0 portfolio-bradcrum">
                <div className="Contact_main_heading">
                    <div className="contact_head">
                        <h3>Terms & Condition</h3>
                        <div className="bread">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/terms-condition">Terms-Condition</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bradcrum section End */}
            <section className='term-condition-section'>
                <div className='container'>

                    <div className='row '>
                        <div className='col-lg-12'>

                            <div className='term-condition-content'>
                                <ul className=''>
                                    {
                                        termsData && termsData.length > 0 ?

                                            termsData.map((item) => (
                                                <li key={item.id}> <span><SlActionRedo className='fs-6' /> </span><span dangerouslySetInnerHTML={{ __html: item.terms_condition }} />
                                                </li>
                                            ))
                                            :
                                            " "
                                    }

                                    {/* ............................................ */}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TermsCondition
