// import React, { useState } from 'react'
// // import { SiAnswer } from "react-icons/si";
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import { FaMinus, FaPlus } from "react-icons/fa6";

// const MyAccordian = ({ question, answer }) => {
//     const [show, setShow] = useState(false);

//     return (
//         <section className='accordian rounded-2' >
//             <div className="card my-3 shadow-sm ">
//                 <div className='main-heading d-flex ' onClick={() => setShow(!show)} >
//                     <p className='icons'> <span className='text-dark'>{show ? <FaMinus className='fs-4 fw-bold' /> : <FaPlus className='fs-4 fw-bold' />}</span></p>
//                     <p className='icons text-justify'>{question}</p>
//                 </div>
//                 {show && <p className='answer d-flex '>
//                     <span>
//                         <IoIosArrowDroprightCircle className='me-3 ans-icon' />
//                     </span>
//                     <span className='text-justify'>
//                         {answer}
//                     </span> </p>}
//             </div>

//         </section>
//     )
// }

// export default MyAccordian


import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { FaMinus, FaPlus } from 'react-icons/fa';

const MyAccordion = ({ question, answer }) => {
    const [show, setShow] = useState(false);

    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = show ? `${contentRef.current.scrollHeight}px` : '0px';
        }
    }, [show]);

    return (
        <section className='accordion'>
            <div className='card my-3 shadow-sm'>
                <div className='main-heading d-flex' onClick={() => setShow(prev => !prev)}>
                    <p className='icons'>
                        {show ? <FaMinus className='fs-5 fw-bold add-icon' /> : <FaPlus className='fs-5 fw-bold  ' />}
                    </p>
                    <p className='question text-justify'>{question}</p>
                </div>
                <div ref={contentRef} className={`accordion-content ${show ? 'open' : ''}`}>
                    <p className='answer d-flex'>
                        <span>
                            <IoIosArrowDroprightCircle className='ans-icon me-3' />
                        </span>
                        <span className='text-justify'>
                            {answer}
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default MyAccordion;
