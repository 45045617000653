import React from "react";
import { FaRegCopyright } from "react-icons/fa6";

const SidebarFooter = () => {
  return (
    <>
      <section className="dashboard_footer">
        <div className="container_fluid">
          <h6 className="text-center">
            {" "}
            Copyright <span className="fs-6"> <FaRegCopyright className="mx-1" /> </span>2024{" || "} Saral Legal Pedia. All rights reserved.
          </h6>
        </div>
      </section>
    </>
  );
};

export default SidebarFooter;
