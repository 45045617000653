import React from "react";
import { ApiData } from "../CommonFile/ContextApi/Context";
// import Loading from "../Loading"

const Herosection = () => {
  const { siteConfig } = ApiData();
  return (
    <>
      {/* <!--Hero pages start--> */}

      <section className="home_section_main_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="Home_deatils">

                {
                  siteConfig && siteConfig !== "" ?
                    <>
                      <h1 dangerouslySetInnerHTML={{ __html: siteConfig.home_heading }} />

                      <p dangerouslySetInnerHTML={{ __html: siteConfig.home_description }}></p>
                    </>
                    :

                    <p className='text-center text-danger fs-5 fw-semibold my-2'>No Data Found !! </p>
                }
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* <!--Hero pages end--> */}
    </>
  );
};

export default Herosection;
