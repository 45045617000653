import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { ApiData } from "../CommonFile/ContextApi/Context";
import profile from "../../../assets/images/Signup.jpg";

const UserSignup = () => {
  const { userRole, domainName } = ApiData();
  const [type, role] = userRole;

  // console.log("type", type, "role", role);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_type: type || "",
    user_role: role || "",
    name: "",
    mobile: "",
    email: "",
    password: "",
    address: "",
  });
  const [viewPassword, setViewPassword] = useState(false);

  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUserSignup = (e) => {
    e.preventDefault();

    console.log("formdata", formData);

    const featchData = async () => {
      try {
        const response = await axios.post(
          `${domainName}/register-new-user`,
          formData
        );

        console.log("response ", response);

        if (response.status === 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "User Register successfully",
          });
          navigate("/login");
        } else {
          alert("Error in registration!");
        }
      } catch (error) {
        if (error.response.status === 422) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "info",
            title: "User Allredy Register",
          });
        }
        console.log("Error ", error);
        console.log(error.response.data.status, "Please Login....");
      }
    };

    featchData();
  };
  return (
    <>
      <div className="container">
        <section className="signup-form shadow">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 rounded">
              <div className="signup-heading">
                <h5> User Register Here </h5>
              </div>
              <h6 className="my-3">
                Welcome User For the visit Register Page !
              </h6>

              <form className="form-data" onSubmit={handleUserSignup}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="number"
                        name="mobile"
                        className="form-control"
                        placeholder="Enter Your mobile Number"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group email-field">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group password-field">
                      <label htmlFor="password">Password</label>
                      <input
                        type={viewPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                      <p
                        className="password-eye position-absolute"
                        onClick={passwordViewHandler}>
                        {viewPassword ? <FaEye /> : <FaRegEyeSlash />}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="address">Address</label>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Enter Your address"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <input
                    type="submit"
                    value="Register"
                    className="all-btn mt-4 "
                  />
                </div>
              </form>
            </div>
            {/* <div className="col-lg-2 col-md-2 col-sm-6 align-content-center ">
              <h4 className="p-5 or-text">Or</h4>
            </div> */}

            <div className="col-lg-5 col-md-5 col-sm-4 d-flex align-items-center">
              <img
                src={profile}
                alt="loginImage"
                title="login_image"
                className="img-fluid "
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserSignup;
