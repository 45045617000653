import axios from 'axios';
import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { TiArrowBack } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { ApiData } from '../../../Pages/CommonFile/ContextApi/Context';

const SpAddTestimonial = ({ handelAdd, id }) => {

    const navigate = useNavigate();
    const { domainName } = ApiData();

    const [rating, setRating] = useState(0);
    const [feedbackdata, setFeedbackData] = useState({
        rating: rating || "",
        user_id: id || "",
        testimonial: "" || null,
    })

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFeedbackData({ ...feedbackdata, [name]: value });

    }

    const handelSubmit = async (e) => {
        e.preventDefault();

        try {

            const res = await axios.post(`${domainName}/add-testimonial`, feedbackdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            // console.log("sent data", res);

            handelAdd()
            setFeedbackData({
                rating: "",
                user_id: "",
                testimonial: ""
            });

            if (res.status === 200) {

                navigate("/service-provider-dashboard/testimonial/")

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: res.data.status === true ? "success" : "error",
                    title: res.data.message,
                });
            }
            else {
                console.log("Somthing Went Worng")
            }


        } catch (error) {
            if (error.response.status === 422) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: error.response.data.status === true ? "success" : "error",
                    title: error.response.data.message,
                });
            }
            else {
                console.log("Somthing Went Worng", error)
            }

            console.log("Axiox Error", error)

        }
    }

    const handleStarClick = (starValue) => {
        setRating(starValue)
        setFeedbackData({
            ...feedbackdata,
            rating: starValue
        });
    };

    // console.log("rating", rating);
    // console.log("feedbackdata", feedbackdata);

    return (

        <div className="dashboard--content px-3">

            <div className='container'>
                <div className='row'>

                    <div className='col-lg-12 col-12 my-3'>
                        <div className='button'>
                            <button className='btn btn-info float-end me-5' onClick={handelAdd}>
                                <TiArrowBack />back
                            </button>
                        </div>
                    </div>

                    <div className='col-lg-12 col-12'>
                        <div className=' text-center mt-4'>
                            <h4>Your Testimonials</h4>
                        </div>
                    </div>
                    <div className='col-12'>
                        <form className='feedback-form px-1 shadow' onSubmit={handelSubmit} >
                            <h4 className='text-center'>Add Testimonials to Your Website.</h4>
                            <div className='star-rating'>
                                <ul className='d-flex'>
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <li
                                            key={value}
                                            className={`star fs-2 ${value <= rating ? 'yellow' : ''}`}
                                            onClick={() => handleStarClick(value)}
                                        >
                                            <FaStar />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='row px-5'>
                                <div className='col-lg-12 col-12'>
                                    <label className='form-group fs-5 my-3'>Testiminoal :- </label>
                                    <textarea
                                        placeholder='Your testimonial '
                                        name="testimonial"
                                        type="text"
                                        className="form-control"
                                        rows="10"
                                        cols="12"
                                        onChange={handelChange}
                                    ></textarea>
                                </div>
                            </div>
                            <div className='mt-3 float-end m-4 pb-5 '>
                                <button type="submit" value="submit" className='all-btn'>Add New Testimonial </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpAddTestimonial;




