import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../../../../assets/css/sidebar.css";
import { FaBars, FaUser } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import mainlogo from "../../../../assets/images/slpfinal.png";
import SidebarMenu from "./SidebarMenu";
import { Outlet } from "react-router-dom";
import Topbar from "../../Topbar/Topbar";
import { GrTextWrap } from "react-icons/gr";
import { ApiData } from "../../../Pages/CommonFile/ContextApi/Context";
import { MdAssignmentAdd } from "react-icons/md";

const routes = [
  {
    path: "/service-provider-dashboard/",
    name: "Profile",
    icon: <FaUser />,
  },

  {
    path: "/service-provider-dashboard/testimonial/",
    name: "Testimonial",
    icon: <GrTextWrap />,
  },
  {
    path: "/service-provider-dashboard/assign-task-table/",
    name: "Assigned Task",
    icon: <MdAssignmentAdd />,
  },
];

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [search, setSearch] = useState("");

  const { siteConfig } = ApiData()

  const toggle = () => setIsOpen(!isOpen);

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "110px",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <div className="main-container d-flex">
        <motion.div
          animate={{
            width: isOpen ? "240px" : "40px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar`}>
          <div className="top_section">
            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="main-logo">
            {isOpen && (
              <Link to="/">
                <img src={siteConfig.logo ? siteConfig.logo : mainlogo} alt="mainlogo" title="main-logo" className="img-fluid logo" />
              </Link>
            )}
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            {isOpen && (
              <motion.input
                type="text"
                placeholder="Search Here ......"
                value={search}
                variants={inputAnimation}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
            <AnimatePresence />
          </div>
          <section className="routes">
            {routes
              .filter((route) =>
                search.toLowerCase() === ""
                  ? true
                  : route.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((route, index) => {
                if (route.subRoutes) {
                  route.subRoutes.filter((route) =>
                    search.toLowerCase() === ""
                      ? true
                      : route.name.toLowerCase().includes(search.toLowerCase())
                  );
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      routes={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      width={100}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeclassname="active">
                    <div className="icon">{route.icon}</div>

                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text">
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>

        <main className="w-100">
          <Topbar />
          {children}
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Sidebar;
