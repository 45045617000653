import React, { useEffect, useState } from 'react'
import EnquiryImg from "../../../assets/images/enquiry (2).jpg"
import Swal from 'sweetalert2'
import axios from 'axios'
import { ApiData } from '../CommonFile/ContextApi/Context'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
const EnquiryTable = () => {

    const { domainName } = ApiData()

    const [enquiryForm, setEnquiryForm] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "-" || "",
        description: "",

    })


    useEffect(() => {
        setEnquiryForm(enquiryForm);
    }, [enquiryForm])

    const handelChange = (e) => {
        const { name, value } = e.target;
        setEnquiryForm({
            ...enquiryForm, [name]: value
        })
    }

    // console.log(enquiryForm);


    const handelSubmit = async (e) => {
        try {
            e.preventDefault();

            const sentData = await axios.post(`${domainName}/add-service-enquiry`, enquiryForm)

            // console.log(sentData);

            if (sentData.status === 200) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: sentData.data.message,
                });

                setEnquiryForm({
                    name: "",
                    email: "",
                    phone: "",
                    description: "",
                    subject: "-",

                })

            }
        } catch (error) {

            setEnquiryForm({
                name: "",
                email: "",
                phone: "",
                description: "",
                subject: "-",

            })

            if (error.response.status === 422) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "warning",
                    title: error.response.data.message,
                });

                setEnquiryForm({
                    name: "",
                    email: "",
                    phone: "",
                    description: "",
                    subject: "-",

                })
            }

        }
    }


    // console.log("enquiry ", enquiryForm)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Enquiry - Saral Legal Pedia | Get in Touch with Us'}</title>
                <meta name="description" content="Get in touch with Saral Legal Pedia for expert legal advice and support. Use our contact form or reach out directly to our team for inquiries, feedback, or assistance. We're here to help you with accurate legal information and resources." />
                <meta name="keywords" content={"Enquiry Saral Legal Pedia,Legal Support Contact, Get in Touch with Legal Experts , Legal Advice Contact Form , Reach Our Legal Team, Contact Legal Information Provide , Enquirys"} />
                <link rel="canonical" href="https://sarallegalpedia.com/" />
            </Helmet>

            {/* Bradcrum section start */}
            <div className="container-fluid p-0 contact-bradcrum">
                <div className="Contact_main_heading">
                    <div className="contact_head">
                        <h3> Enquire </h3>
                        <div className="bread">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/enquiry-form">Enquire Now</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bradcrum section end*/}
            <section className='home-enquiry-form-section m-3 '>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6  col-md-6 col-sm-12">
                            <form className='home-enquiry-form shadow-sm bg-white' onSubmit={handelSubmit} >
                                <h4>Enquiry Form : - </h4>
                                <div className="row">
                                    <div className='col-lg-12 col-md-6 col-sm-12 '>
                                        <div className='form-data'>
                                            <label className='form-group' htmlFor='name'> Name </label>
                                            <input name='name' type='text' id="name" placeholder='Your name ' className='form-control' required onChange={handelChange} autoComplete="off" value={enquiryForm.name} />
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-6 col-sm-12'>
                                        <div className='form-data'>
                                            <label className='form-group' htmlFor='email'> Email </label>
                                            <input name='email' type='email' id="email" placeholder='Your email ' className='form-control' required onChange={handelChange} autoComplete="off" value={enquiryForm.email} />
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-6 col-sm-12'>
                                        <div className='form-data'>
                                            <label className='form-group' htmlFor='phone'> Phone </label>
                                            <input name='phone' type='number' id="phone" placeholder='Your phone number ' maxLength={12} className='form-control' required onChange={handelChange} autoComplete="off" value={enquiryForm.phone} />
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='form-data '>
                                            <label className='form-group' htmlFor='subject'> Subject </label>
                                            <input name='subject' type='hidden' id="subject" placeholder='Your subject ' className='form-control' required onChange={handelChange} autoComplete="off" value={enquiryForm.subject} />
                                        </div>
                                    </div> */}
                                    <div className='col-12 '>
                                        <div className='form-data'>
                                            <label className='form-group' htmlFor='description'> Description </label>
                                            <input name='description' type='text' id="description" placeholder='Your description ' className='form-control' required onChange={handelChange} autoComplete="off" value={enquiryForm.description} />
                                        </div>
                                    </div>
                                    <div className='col-12 d-flex justify-content-end my-3'>
                                        <input name='button' type='submit' className='btn btn-primary' value="Submit" />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div className="enquiry-form-image">
                                <img src={EnquiryImg} alt="enquiry pic" title='enquiry' />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default EnquiryTable;