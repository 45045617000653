import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import img from "../../../../assets/images/news1.png"
import SpEditTestimonial from './SpEditTestimonial';
import SpAddTestimonial from './SpAddTestimonial';
import { ApiData } from '../../../Pages/CommonFile/ContextApi/Context';

const SpViewTestimonial = () => {
    const { domainName } = ApiData();
    const id = localStorage.getItem("id")
    let index = 0;

    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selecteditem, setSelectedItem] = useState("");
    const [loading, setLoading] = useState(true);
    const [testimonialData, setTeasimonialData] = useState([{}]);

    try {
        useEffect(() => {
            const fetchData = async () => {
                const res = await axios.get(`${domainName}/get-testimonial/${id}`)

                // console.log("testimonial ", res);

                if (res.status === 200) {
                    setTeasimonialData(res.data);
                    setLoading(false)

                }
                else {
                    console.log("Somthing went wrong ")
                }
            }
            fetchData()
        }, [domainName, isOpen, id])

    } catch (error) {
        console.log("Axios Error", error)

    }
    const handelEdit = (item) => {
        setSelectedItem(item);
        console.log("edit ", item)

        setIsOpen(!isOpen)
        setIsEdit(!isEdit);
    }

    const handelAdd = () => {
        setIsOpen(!isOpen)

    }


    // console.log("testimonialData", testimonialData );



    if (loading) {
        return <div>
            ...loading
        </div>
    }

    return (
        < >
            <div className="dashboard--content px-3">
                <div className="main-header mt-4">
                    <div className="header--sidebar">
                        <h3>
                            <Link to="/service-provider-dashboard/" className="header--menu">
                                <span> Home</span>
                            </Link>
                            /
                            <span> View Testimonial</span>
                        </h3>
                    </div>
                </div>


                <div className='container'>
                    {isOpen ? (
                        <>
                            {
                                isEdit ?
                                    <SpEditTestimonial handelEdit={handelEdit} testimonialData={selecteditem} id={id} />
                                    :
                                    <SpAddTestimonial handelAdd={handelAdd} testimonialData={selecteditem} id={id} />
                            }


                        </>
                    ) : (


                        <div className='row'>
                            <div className='col-lg-12 col-12 my-3'>

                                <div className='button d-flex justify-content-end'>
                                    <Link >
                                        <button className='btn btn-info' onClick={handelAdd}>
                                            Add new Testimonial
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            <div className='col-lg-12 col-12'>
                                <div className='testimonial-contant mt-5'>
                                    <div className='table-responsive'>
                                        <table className="table shadow ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S No.</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Rating</th>
                                                    <th scope="col">Testimonial</th>
                                                    <th scope="col">Action</th>

                                                </tr>
                                            </thead>
                                            {!testimonialData || testimonialData.status === 'false' ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6">
                                                            <h4 className='text-center'>
                                                                No Data Found!
                                                            </h4>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>

                                                    {
                                                        <tr>
                                                            <td >{index + 1}</td>
                                                            <td >{testimonialData.name}</td>
                                                            <td> <img src={testimonialData.image || img} className='testimonial-img' alt={testimonialData.title} /></td>
                                                            <td >{testimonialData.rating}</td>
                                                            <td >{testimonialData.testimonial}</td>
                                                            <td >

                                                                <button className='all-btn' type="button" value="edit" name='edit' onClick={() => handelEdit(testimonialData)}>
                                                                    Edit
                                                                </button>

                                                            </td>
                                                        </tr>


                                                    }
                                                </tbody>
                                            )}
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </div>

        </>
    )
}


export default SpViewTestimonial;
