import React, { useEffect, useState } from 'react'
import { ApiData } from "../Pages/CommonFile/ContextApi/Context"
import axios from 'axios';

const VideoSection = () => {

    const { domainName } = ApiData();
    const [videoData, setVideoData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            const res = await axios.get(`${domainName}/video-view`);

            setVideoData(res.data.records);

        }
        fetchData();
    }, [domainName])


    return (
        <section className="video-section my-3">
            <div className="container">
                <h4>Videos : - </h4>
                <div className="row">
                    {
                        videoData && videoData.length > 0 ?
                            videoData.map((item) => (
                                <div className="col-lg-4 col-md-4 col-6" key={item.id}>
                                    <div className="main-video-section">
                                        <video controls>
                                            <source src={item.video} type="video/mp4" title={item.title} />
                                        </video>
                                    </div>
                                </div>
                            ))

                            :
                            ""
                    }

                </div>
            </div>
        </section>
    )
}

export default VideoSection