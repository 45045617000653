import React, { useEffect, useState } from "react";
import about from "../../assets/images/law__about.webp";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiData } from "./CommonFile/ContextApi/Context";
import { Helmet } from "react-helmet";

const About = () => {
  const { domainName } = ApiData();
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${domainName}/aboutus-view`
        );


        // console.log(res.data.records);

        const data = res.data.records;
        setAboutData(data);
      } catch (error) {
        console.log("Axios Error", error);
      }
    };
    fetchData();
  }, [domainName]);

  return (
    <>
      <Helmet>
        <title> {aboutData && aboutData.seo_title ? aboutData.seo_title : "About Us - Saral Legal Pedia | Legal Information and Advice"
        }</title>
        <meta name="description" content={aboutData && aboutData.seo_description ? aboutData.seo_description : "Learn more about Saral Legal Pedia, your go-to source for accurate and up-to-date legal information. Discover our mission, values, and team of experts."} />

        <meta property="og:title" content={aboutData.seo_title || "About "} />

        <meta name="keywords" content={aboutData && aboutData.seo_keyword ? aboutData.seo_keyword : "Saral legal pedia,Legal guides,Easy legal advice,Legal advice platform,Legal information website,Legal resources for beginners,Privacy policy services,FAQs,   "} />

        <link rel="canonical" href={aboutData && aboutData.canonical_links ? aboutData.canonical_links : "www.sarallegalpedia.com/about"} />

      </Helmet>





      {/* Bradcrum section start */}
      <div className="container-fluid p-0 about-bradcrum">
        <div className="Contact_main_heading">
          <div className="contact_head">
            <h3> About Us</h3>
            <div className="bread">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/about">About us</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

        </div>
      </div>
      {/* Bradcrum section End */}
      {/* <!-- About Us Section Start--> */}
      <section id="section2" className="about_section_main_wrapper">
        <div className="container">
          <div className="row">
            {
              aboutData && aboutData !== "" ?
                <>
                  <div className="col-lg-6 col-md-6 col-12" key={aboutData.id}>
                    <div className="about_head">
                      {/* <h3>About Us</h3> */}
                      <p className="about-contact mt-3 mb-0" dangerouslySetInnerHTML={{ __html: aboutData.description }} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                    <div className="about-image">
                      <img
                        src={aboutData.about_image ? aboutData.about_image : about}
                        alt={aboutData.title}
                        title={aboutData.title}
                        className="rounded-3"
                      />
                    </div>
                  </div>
                </>
                :

                <p className='text-center text-danger fs-5 fw-semibold my-2'>No Data Found !! </p>
            }


          </div>
        </div>
      </section>
      {/* <!-- About Section End--> */}
    </>
  );
};

export default About;
