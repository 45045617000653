import React, { useState } from "react";
import contactimg from "../../assets/images/contact-us-1.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ApiData } from "../Pages/CommonFile/ContextApi/Context"
import { Helmet } from "react-helmet";


const Contact = () => {

  const { siteConfig, domainName } = ApiData()

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    number: "",
    city: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${domainName}/contact-create`,
        formData
      );
      if (res.status === 200) {
        // alert("SuccessFully Send Massage");
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Data Sent successfully",
        });

        setFormData(
          {
            first_name: " ",
            last_name: " ",
            email: " ",
            number: " ",
            address: " ",
            message: " ",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // console.log(formData);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Contact Us - Saral Legal Pedia | Get in Touch with Us'}</title>
        <meta name="description" content="Get in touch with Saral Legal Pedia for expert legal advice and support. Use our contact form or reach out directly to our team for inquiries, feedback, or assistance. We're here to help you with accurate legal information and resources." />
        <meta name="keywords" content={"Contact Saral Legal Pedia,Legal Support Contact, Get in Touch with Legal Experts , Legal Advice Contact Form , Reach Our Legal Team, Contact Legal Information Provide"} />
        <link rel="canonical" href="https://sarallegalpedia.com/" />
      </Helmet>

      {/* Bradcrum section start */}
      <div className="container-fluid p-0 contact-bradcrum">
        <div className="Contact_main_heading">
          <div className="contact_head">
            <h3> Contact Us </h3>
            <div className="bread">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/contact">Contact us</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Bradcrum section end*/}

      {/* <!--Contact Form Start-->  */}
      <section className="contact_section_main_wrapper">
        <div className="contact_form">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="form_box  my-3 shadow">
                  <p>GET IN TOUCH</p>
                  <h3>
                    <span>Reach</span> Out To Us
                  </h3>
                  <form onSubmit={handelSubmit}>
                    <div className="row">
                      <div className="col-md-6 contact-details">
                        <label
                          htmlFor="validationDefault01"
                          className="form-label"></label>
                        <input
                          type="text"
                          className="form-control"
                          name="full_name"
                          value={formData.first_name}
                          id="validationDefault01"
                          placeholder="Your Fullname"
                          required
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                      </div>

                      <div className="col-md-6">
                        <label
                          htmlFor="validationDefault03"
                          className="form-label"></label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          id="validationDefault03"
                          placeholder="Your Email"
                          required
                          onChange={handleInputChange}
                          autoComplete="off"

                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="validationDefault04"
                          className="form-label"></label>
                        <input
                          type="number"
                          className="form-control"
                          name="number"
                          value={formData.number}
                          id="validationDefault04"
                          placeholder="Your Number"
                          required
                          onChange={handleInputChange}
                          autoComplete="off"

                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="validationDefault05"
                          className="form-label"></label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          value={formData.address}
                          id="validationDefault05"
                          placeholder="Your City"
                          required
                          onChange={handleInputChange}
                          autoComplete="off"

                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="validationDefault06"
                          className="form-label"></label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="message"
                          value={formData.message}
                          id="validationDefault06"
                          placeholder="Your Message"
                          required
                          onChange={handleInputChange}
                          autoComplete="off"
                        ></textarea>
                      </div>
                    </div>
                    <button type="submit" className="all-btn">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="enquiery_box my-3 rounded-2">
                  {/* <h4>
                    Say
                    <span> Hello!</span>
                  </h4>
                  <p>
                    At elementum eu facilisis sed odio morbi quis commodo. Nulla
                    pharetra diam.
                  </p> */}
                  <div className="Enquiery_main_pic mt-2  ">
                    <div className="enquiry_pic d-flex justify-content-center">
                      <img src={contactimg} alt="contact images" className="img-fluid" />
                    </div>
                    <div className="enquiry_icon">
                      {
                        siteConfig && siteConfig !== " " ?
                          <ul>
                            <li>
                              <Link to={siteConfig.facebook}>
                                <i className="fa-brands fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={siteConfig.twitter}>
                                <i className="fa-brands fa-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={siteConfig.instagram}>
                                <i className="fa-brands fa-instagram"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={siteConfig.youtube}>
                                <i className="fa-brands fa-youtube"></i>
                              </Link>
                            </li>
                          </ul>
                          :
                          ""}
                    </div>
                  </div>
                  <div className="enquiery_dtl">{
                    siteConfig && siteConfig !== " " ?
                      <>
                        <p>
                          <i className="fa-solid fa-location-dot "></i> <span className="ms-2">{siteConfig.address}, {siteConfig.city}, {siteConfig.state}, {siteConfig.pincode} </span>
                        </p>
                        <p>
                          <i className="fa-solid fa-envelope "></i> <span className="ms-2">{siteConfig.email}</span>
                        </p>
                        <p>
                          <i className="fa-solid fa-phone "></i> <span className="ms-2">{siteConfig.contact_number}</span>
                        </p>
                      </>
                      :

                      " "
                  }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Contact Form End-->
    <!--Google Map Section Start--> */}
      <section className="map_section_main_wrapper">
        <div className="container-fluid p-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.2074790445718!2d75.88317937476138!3d22.72052842753329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd398663f497%3A0xa6d131f05a32a8!2sVibrant%20Tower%2C%209%2C%20Manorama%20Ganj%2C%20above%20Ola%20%26%20Nissan%20Showroom%2C%20Geeta%20Bhavan%2C%20Manorama%20Ganj%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1722231170159!5m2!1sen!2sin"
            title="Googal map"
            width="100%"
            height="450"
            loading="lazy"></iframe>
        </div>
      </section>
    </>
  );
};

export default Contact;
