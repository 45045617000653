import { createContext, useContext, useState } from "react";

const ApiContext = createContext();

export const ApiData = () => useContext(ApiContext);

export const ApiContextProvider = ({ children }) => {

  const domainName = "https://portal.sarallegalpedia.com/api";

  const [userRole, setuserRole] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [siteConfig, setSiteConfig] = useState({});
  const [updateAlert, setUpdateAlert] = useState({});
  const [faqSection, setSetFaqSection] = useState([]);


  // console.log("contacxt api run .....")


  const userRoleFun = (data) => {
    // console.log("useritdfdff", data);
    setuserRole([data.user_type, data.title,]);
    // console.log(userRole);
  };

  const siteConfigData = (data) => {
    // console.log("Site Config Data", data);
    setSiteConfig(data);

  }
  const updateAlertData = (data) => {
    // console.log("Site Config Data", data);
    setUpdateAlert(data);

  }
  const faqSectionData = (data) => {
    // console.log("Site faq  Data", data);
    setSetFaqSection(data);

  }
  const checkSubscription = (data) => {
    setSubscription(data)
    console.log("subscription-Id ", data)
  }
  return (
    <ApiContext.Provider value={{ domainName, userRoleFun, userRole, checkSubscription, subscription, siteConfigData, siteConfig, updateAlertData, updateAlert, faqSection, faqSectionData }}>
      {children}
    </ApiContext.Provider>
  );
};
