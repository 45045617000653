import React, { useState } from 'react'
import { PiCaretDoubleRightFill } from "react-icons/pi";
import { ApiData } from '../CommonFile/ContextApi/Context';
import Modal from 'react-bootstrap/Modal';


const LatestUpdate = ({ShowModal, CloseModal}) => {
    const { updateAlert } = ApiData();
    
    return (
        <>
            <section className='news-alert-section'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-6'>
                            {
                                updateAlert && updateAlert !== "" ?

                                    <Modal
                                        show={ShowModal}
                                        onHide={CloseModal}
                                        dialogClassName="modal-90w"
                                        aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title id="example-custom-modal-styling-title">
                                                Updates &  Alerts
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='news-alert-description  '>
                                                <p className="latest-update-section">
                                                    <span className='news-alert-icon'>
                                                        <PiCaretDoubleRightFill className='arrow-icon1' />
                                                        <PiCaretDoubleRightFill className='arrow-icon2' />
                                                    </span>

                                                    {
                                                        updateAlert && updateAlert !== ""
                                                            ? <>
                                                                <span dangerouslySetInnerHTML={{ __html: updateAlert.description }} />
                                                            </>
                                                            :
                                                            " "
                                                    }
                                                </p>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default LatestUpdate
