import React from 'react'
import { IoDiamondOutline, IoRocketOutline } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { FaHandshakeSimple } from 'react-icons/fa6';

const WhyChooseUs = () => {
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12'>
                        <div className='why-choose-us mt-3'>
                            <div className='heading-section'>
                                <h3 >Why Choose Us</h3>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><GrUserExpert className='why-choose-icon' /></span> Expert Professionals</h4>
                                        <div className='card-content'>
                                            <p className='text-left'> We have the team of qualified Company Secretaries, Chartered Accountants, Accountants, Lawyers and skilled Employees and trainees.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><GiReceiveMoney className='why-choose-icon' /></span>Affordable Pricing</h4>
                                        <div className='card-content'>
                                            <p className='text-left'> We believe that quality consultation shouldn’t break the bank. We provide  services without creating burden on pocket of our clients, ensuring that everyone can access expert advice without worrying about exorbitant fees.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><FaHandshakeSimple className='why-choose-icon' /></span>Convenient Access</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>Say goodbye to lengthy appointments and travel hassles. With our online platform, you can access professional consultation services from the comfort of your home or office, anytime you need them.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>

                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><IoRocketOutline className='why-choose-icon' /></span>Comprehensive Support</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>From legal advice to tax planning and compliance, we offer a wide range of services to cover all your business needs. Our comprehensive support ensures that you have all the expert help.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>
                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><IoDiamondOutline className='why-choose-icon' /></span>Trusted Community </h4>
                                        <div className='card-content'>
                                            <p className='text-left'>Join a thriving community of professionals and business owners who trust our platform for their consultation needs. Our commitment to quality and reliability has made us a trusted partner.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                                    <div className='card p-4'>

                                        <h4 className='d-flex gap-3 align-items-center fw-semi-bold my-3'><span><MdOutlineSupportAgent className='why-choose-icon' /></span>24x7 Availability</h4>
                                        <div className='card-content'>
                                            <p className='text-left'>Our platform is available round the clock to cater to your needs. Whether it’s a late-night query or an urgent consultation, our professionals are ready to assist you anytime, anywhere.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default WhyChooseUs