import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { ApiData } from "./CommonFile/ContextApi/Context";
import { Helmet } from "react-helmet";
import VideoSection from "./VideoSection";

const Gallery = () => {
    const { domainName, siteConfig } = ApiData();
    const [galleryData, setGalleryData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(
                ` ${domainName}/gallery-view`
            );

            // console.log(res);
            const data = res.data.records;
            setGalleryData(data);
        };
        fetchData();
    }, [domainName]);



    return (
        <>

            <Helmet>
                <title> {siteConfig.seo_title || "Saral Legal Pedia | Comprehensive Legal Information & Resources"}</title>

                <meta name="description" content={siteConfig.seo_description || "Welcome to Saral Legal Pedia, your trusted source for comprehensive legal information and expert guidance. Discover valuable legal resources, updates, and advice tailored to meet your needs. Explore our website to access reliable legal insights and connect with our knowledgeable team."

                } />


                <meta name="keywords" content={siteConfig.seo_keyword || "Saral legal pedia,Legal guides,Easy legal advice,Legal advice platform,Legal information website,Legal resources for beginners, services,FAQs,gallery   "} />

                <link rel="canonical" href={"https://sarallegalpedia.com/"} />
            </Helmet>
            {/* Bradcrum section start */}
            <div className="container-fluid p-0 gallery-bradcrum">
                <div className="Contact_main_heading">
                    <div className="contact_head">
                        <h3>Gallery </h3>
                        <div className="bread">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/gallery">Gallery</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bradcrum section End */}

            {/* <!--gallery Section Start--> */}
            <section className="gallery_section_main_wrapper mt-4">
                <div className="container">
                    <div className="row">
                        {
                            galleryData && galleryData.length > 0 ?
                                galleryData.map((item) => {
                                    return (

                                        <div className="col-lg-3 col-md-4 col-6" key={item.id}>
                                            <div className="gallery_img shadow">
                                                <img
                                                    className="img-fluid"
                                                    src={item.gallery_image}
                                                    alt={item.title}
                                                    title={item.title}
                                                />
                                            </div>
                                        </div>

                                    );
                                })
                                    .reverse()

                                : " "
                        }
                    </div>


                </div>
            </section>
            {/* <!--gallery Section End--> */}

            <VideoSection />


        </>
    );
}

export default Gallery