import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { ApiData } from "../CommonFile/ContextApi/Context";
import profile from "../../../assets/images/Signup.jpg";

const Signup = () => {
  const navigate = useNavigate();

  const { domainName } = ApiData();

  const [formData, setFormData] = useState({
    user_role: "",
    name: "",
    mobile: "",
    email: "",
    password: "",
  });
  const [viewPassword, setViewPassword] = useState(false);

  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    // console.log(formData);

    const featchData = async () => {
      try {
        const response = await axios.post(
          `${domainName}/register-new-user`,
          formData
        );

        if (response.status === 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          navigate("/login");

          setFormData("");
        } else {
          if (response.status === 422) {
            alert("funtion")
          }
          console.log("Somthing went wrong ")
        }
      } catch (error) {

        if (error.response.status === 422) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: error.response.data.message,

          });
        }


        console.log("Error message", error);
      }
    };

    featchData();
  };
  return (
    <>
      <div className="container">
        <section className="signup-form shadow">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 rounded bg-white">
              <div className="signup-heading">
                <h5>Register Here </h5>
              </div>

              <form className="form-data" onSubmit={handleSignUp}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        type="number"
                        name="mobile"
                        className="form-control"
                        placeholder="Enter Your mobile Number"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>User Role</label>
                      <input
                        type="text"
                        name="user_role"
                        className="form-control"
                        placeholder="CA/CS/Lawyer/Accountant"
                        value={formData.user_role}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group email-field">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group password-field">
                      <label>Password</label>
                      <input
                        type={viewPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Enter Your password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                      <p
                        className="password-eye position-absolute"
                        onClick={passwordViewHandler}>
                        {viewPassword ? <FaEye /> : <FaRegEyeSlash />}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <input
                    type="submit"
                    value="Register"
                    className="all-btn mt-4 "
                  />
                </div>
                <p className="mt-3">
                  {" "}
                  Already have an account ?
                  <Link to="/login" className="text-decoration-none">
                    <span className="px-3">Login</span>
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-6 align-content-center">
              <img
                src={profile}
                alt="loginImage"
                title="login_image"
                className="img-fluid"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Signup;
