import React from "react";
import Header from "../../../Component/Pages/CommonFile/Header";
import Footer from "../../../Component/Pages/CommonFile/Footer";
import { Outlet } from "react-router-dom";
import TotalUser from "../../Pages/HomePages/TotalUser"
import MainHeading from "../../Pages/HomePages/MainHeading";


const Layout = () => {

  return (
    <>
      <TotalUser />
      <Header />
      <MainHeading />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
