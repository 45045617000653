import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiData } from "../CommonFile/ContextApi/Context";
import { Helmet } from "react-helmet";

const SubServices = () => {
    const { domainName } = ApiData();

    const { slug } = useParams();

    // console.log(slug)

    const [showServices, setShowServices] = useState([]);

    const [serviceForm, setServiceForm] = useState({
        name: "",
        email: "",
        phone: "",
        subject: slug,
        description: '',

    })
    useEffect(() => {
        setServiceForm({
            ...serviceForm,
            subject: slug,
        })
    }, [slug])

    // console.log("serviceForm", serviceForm)

    const handelChange = (e) => {
        const { name, value } = e.target;
        setServiceForm({
            ...serviceForm, [name]: value
        })

    }

    // console.log(serviceForm);

    const handelSubmit = async (e) => {
        try {
            e.preventDefault();

            const sentData = await axios.post(`${domainName}/add-service-enquiry`, serviceForm)

            // console.log(sentData);

            if (sentData.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: sentData.data.message,
                });

                setServiceForm({
                    name: "",
                    email: "",
                    phone: "",
                    description: "",
                    subject: slug,

                })
            }
        } catch (error) {
            setServiceForm({
                name: "",
                email: "",
                phone: "",
                description: "",
                subject: slug,

            })

            if (error.response.status === 422) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "warning",
                    title: error.response.data.message,
                });
            }

        }
    }


    useEffect(() => {

        const servicesFetchData = async () => {

            const res = await axios.get(`${domainName}/get-service-subservice`);

            const data = res.data;
            setShowServices(data);

            // console.log(data);
        };
        servicesFetchData();

    }, [domainName])

    const filterData = showServices.flatMap(item =>
        item.subservices.filter(elem => elem.slug === slug)
    );

    // console.log("filterData", filterData);


    return (
        <>
            <Helmet>
                <title> {showServices && showServices.title ? showServices.title : "Services - Saral Legal Pedia | Legal Information and Advice"
                }</title>
                <meta name="description" content={showServices && showServices.seo_description ? showServices.seo_description : "At Saral Legal Pedia, we offer a range of services to help you understand and navigate legal matters. Our services include expert articles, comprehensive guides, and more."} />

                <meta property="og:title" content={showServices.title || "Services "} />

                <meta name="keywords" content={showServices && showServices.seo_keyword ? showServices.seo_keyword : "Saral legal pedia, Legal guides,Easy legal advice,Legal advice platform,Legal information website, Legal resources for beginners,Privacy policy services, FAQs,   "} />

                <link rel="canonical" href={showServices && showServices.canonical_links ? showServices.canonical_links : "www.sarallegalpedia.com"} />

            </Helmet>

            <div className="container-fluid p-0">
                <div className="services_main_heading">
                    <div className="service_head">
                        {filterData.map((item, index) => (
                            // console.log("data", item),
                            <div key={index + 1}>
                                <h3>{item.title}</h3>
                            </div>
                        ))}
                        <div className="bread">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="">Service</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--service  bradcrum Section End -->

      
    <!--service Section start --> */}
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="service_menu">
                            <ul>
                                {
                                    filterData.map((item) => (
                                        <li key={item.id} dangerouslySetInnerHTML={{ __html: item.description }} ></li>
                                    ))}
                            </ul>
                        </div>
                    </div>


                    <div className="col-lg-6 ">
                        <div className="serivce_form mt-3">
                            <form className="inner" onSubmit={handelSubmit}>
                                <div className="form-row">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Full Name"
                                        name="name"
                                        onChange={handelChange}
                                        value={serviceForm.name}
                                        required
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-row">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        placeholder="Email"
                                        value={serviceForm.email}
                                        onChange={handelChange}
                                        required
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-row">
                                    <input
                                        type="number"
                                        name="phone"
                                        required
                                        autoComplete="off"
                                        className="form-control"
                                        onChange={handelChange}
                                        value={serviceForm.phone}
                                        placeholder="Phone"
                                    />
                                </div>

                                <div className="form-row">
                                    <textarea
                                        type="text"
                                        required
                                        autoComplete="off"
                                        name="description"
                                        rows="3"
                                        className="px-2 py-1 w-100 service-description-texarea"
                                        value={serviceForm.description}
                                        onChange={handelChange}
                                        placeholder="Description">
                                    </textarea>
                                </div>
                                <button type="submit" name="submit" className="btn btn-primary">
                                    Enquire Now
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SubServices