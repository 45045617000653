import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import profile from "../../../assets/images/login.jpg";
import { ApiData } from "../CommonFile/ContextApi/Context";

const Login = () => {

  const { domainName } = ApiData();

  const [viewPassword, setViewPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${domainName}/user-login`,
        formData
      );
      const data = res.data;

      // console.log(res);

      if (res.status === 200) {
        localStorage.setItem("token", data.token.plainTextToken);
        localStorage.setItem("user", data.name);
        localStorage.setItem("id", data.id);
        localStorage.setItem("user_role", data.user_role);

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Login successfully",
        });
        setFormData({
          email: " ",
          password: " ",
        })
        navigate("/service-provider-dashboard");
      }
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: error.response.data.comment,
      });
      setFormData({
        email: " ",
        password: " ",
      })

      console.log("axios Error", error);

    }
  };

  return (
    <>
      <div className="container">
        <section className="login-form shadow ">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 rounded ">
              <div className="login-heading">
                <h5>Login Here </h5>
              </div>
              {/* <h6 className="my-3 welcome-msg">
                Welcome Login Page !
              </h6> */}

              <form className="form-data" onSubmit={handleSubmit}>
                <div className="email-field form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder=" Enter Your Email "
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="password-field form-group">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type={viewPassword ? "text" : "password"}
                    name="password"
                    placeholder=" Enter Your Password"
                    value={formData.password}
                    maxLength={20}
                    minLength={4}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                  />
                  <p
                    className="password-eye position-absolute"
                    onClick={passwordViewHandler}>
                    {viewPassword ? <FaEye /> : <FaRegEyeSlash />}
                  </p>
                </div>
                {/* <div className="pass d-flex justify-content-end mt-2">
                  <Link to="/forget-password">Forgot password ?</Link>
                </div> */}
                <div>
                  <input
                    type="submit"
                    value="Login"
                    className="all-btn mt-4 "
                  />
                </div>
                <p className="account-btn mt-3">
                  {" "}
                  Don't have an account ?
                  <Link to="/signup" className="text-decoration-none">
                    <span className="px-3">Register</span>
                  </Link>
                </p>
              </form>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 align-content-center ">
              <h4 className="p-5 or-text">Or</h4>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
              <div className="">
                <img
                  src={profile}
                  alt="loginImage"
                  title="login_image"
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
