import React, { useEffect, useState } from "react";
import footerimg from "../../../assets/images/slpfinal.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaWhatsappSquare, FaPhoneSquareAlt, FaInstagram, FaStar } from "react-icons/fa";
import payLogo from "../../../assets/images/pay (21).png"
import { MdEmail } from "react-icons/md";
import Swal from "sweetalert2";
import { ApiData } from "./ContextApi/Context";
import { IoIosArrowForward } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import QRCode from "qrcode.react";
import AddUserReview from "./AddUserReview";


const Footer = () => {
  const { siteConfig, domainName } = ApiData()
  const [emailData, setEmailData] = useState({
    newsletter_email: "",
  })
  const [show, setShow] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [accountDetails, setAccountDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${domainName}/account-details`);
      setAccountDetails(res.data.records);
    }

    fetchData();
  }, [domainName])

  // console.log(siteConfig);

  const handleReviewClose = () => setShowReview(false);
  const handleReviewShow = () => setShowReview(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);



  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData, [name]: value
    }
    )

  }

  const sendEmail = async (e) => {
    // alert(" SuccessFully Sent your Email")

    try {
      e.preventDefault();
      const res = await axios.post(`${domainName}/add-newsletter`, emailData)

      if (res.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Email successfully Sent ",
        });

        setEmailData({ newsletter_email: '' })

      }
    } catch (error) {
      if (error.response.status === 422) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "error",
          title: "Email Allredy Sent ",
        });
      }

      console.error("Axios Error", error)

    }
  }

  return (
    <>
      {/* <!--Footer section Start--> */}

      <div className="container-fluid px-0">
        <footer className="footer">
          <div className="row justify-content-center pt-3 mt-3 mb-2 mx-0" >

            <div className="col-lg-3 col-md-2 col-sm-6">


              <h5 className="mb-3 mt-2 text-warning text-decoration-underline ">Address</h5>

              <p>
                {siteConfig.address} - {siteConfig.pincode}, {siteConfig.city},   {siteConfig.state}
                <br />
              </p>
              <br />
              <img
                src={siteConfig.logo ? siteConfig.logo : footerimg}
                alt="footer img-fluid"
                className="footer-img"
                title="main-logo"
              />
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6">
              <h5 className="mb-3 mt-2 text-warning text-decoration-underline ">Informative Links</h5>
              <ul className="nav flex-column ">
                <li className="nav-item mb-2">
                  <Link to="/" className="text-decoration-none footer-hover ">
                    <IoIosArrowForward />
                    Home
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/about" className="text-decoration-none footer-hover">
                    <IoIosArrowForward />
                    About
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/services/under-companies-act-2013"
                    className="text-decoration-none footer-hover ">
                    <IoIosArrowForward />
                    Services
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/contact-us" className="text-decoration-none footer-hover ">
                    <IoIosArrowForward />
                    Contact
                  </Link>
                </li>
                <li className="user-review-anime mb-2" onClick={handleReviewShow}>
                  <p className="user-review-anim-text">
                    {/* <IoIosArrowForward /> */}
                    User Review
                  </p>
                </li>
              </ul>
            </div>


            {/* Open Model User Review Start */}

            <Modal className="user-review" show={showReview} onHide={handleReviewClose}>
              <Modal.Header closeButton>
                <Modal.Title>User Review Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddUserReview handleShow={handleReviewClose} />
              </Modal.Body>
            </Modal>


            {/* Open Model User Review End */}

            <div className="col-lg-3 col-md-3 col-sm-6">
              <h5 className=" mt-2 text-warning text-decoration-underline">Newsletter</h5>
              <div className="newselatter my-3">
                <p className="p-0 ">
                  Subscribe to our newsletter to receive update on the latest news
                </p>
              </div>
              <form onSubmit={sendEmail}>
                <div className=" subscription-section d-flex align-items-center">

                  <div className="newslatter-email">
                    <input type="email" required placeholder="Plese Enter your Email" value={emailData.newsletter_email} name="newsletter_email" onChange={handelChange} className="newselatter-input " />
                  </div>
                  <div className="subscription">
                    <button
                      type="btn submit"
                      name="submit"
                      className="subscription-btn btn-secondary d-flex align-items-center gap-2 ">
                      <i className="fa-brands fa-telegram text-dark fs-2" title="subscript us "></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <h5 className=" mt-2 text-warning text-decoration-underline">Contact</h5>
              <ul className="footer_contact_detail mt-4 p-0">
                <li className="nav-item my-3">
                  <p className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-envelope text-danger fs-5"></i>
                    <Link to={`mailto:${siteConfig.email}`} target="_blank" className="text-decoration-none">
                      <span className="footer-hover">
                        {siteConfig.email}
                      </span>
                    </Link>
                  </p>
                </li>
                <li className="nav-item my-3">

                  <Link to={`mailto:${siteConfig.alternate_email} `} target="_blank" className="text-decoration-none ms-4">
                    <span className="footer-hover ms-1">
                      {siteConfig.alternate_email}
                    </span>
                  </Link>

                </li>

                <li className="nav-item my-3">
                  <p className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-phone text-danger fs-5"></i>
                    <Link to={`tel:${siteConfig.contact_number} `} target="_blank" className="text-decoration-none">
                      <span className="footer-hover">
                        {siteConfig.contact_number}
                      </span>
                    </Link>
                  </p>
                </li>
                <li className="nav-item my-3">

                  <Link to={`tel:${siteConfig.alternate_contact_number} `} target="_blank" className="text-decoration-none ms-4">
                    <span className="footer-hover ms-1">
                      {siteConfig.alternate_contact_number}
                    </span>
                  </Link>

                </li>
              </ul>
            </div>

          </div>

          <hr />

          <div className="row me-0">
            <div className="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="m-2">
                Copyright @ 2024 All right resvresd by

                <Link to="https://sarallegalpedia.com/" target="_blank" className="text-decoration-none">
                  <span className="company-name"> sarallegalpedia </span>
                </Link>|
                <Link className="text-decoration-none" to="/privacy-policy"><span className="website-name"> Privacy-Policy   </span></Link>|
                <Link className="text-decoration-none" to="/terms-condition"> <span className="website-name"> Terms-Condition </span></Link> |
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12  d-flex justify-content-center align-items-center gap-3 nav-item">
              <div className="footer-icon">
                <Link target="_blank" to={siteConfig.facebook}>
                  <i className="fa-brands fa-facebook-f p-3 " title="facebook"></i>
                </Link>
              </div>
              <div className="footer-icon">
                <Link target="_blank" to={siteConfig.instagram}>
                  <i className="fa-brands fa-instagram p-3  " title="instagram"></i>
                </Link>
              </div>
              <div className="footer-icon">
                <Link target="_blank" to={siteConfig.twitter}>
                  <i className="fa-brands fa-twitter p-3  " title="twitter"></i>
                </Link>
              </div>
              <div className="footer-icon">
                <Link target="_blank" to={siteConfig.youtube}>
                  <i className="fa-brands fa-youtube p-3  " title="youtube"></i>
                </Link>
              </div>
            </div>
          </div>

        </footer>

      </div>

      <div className="social-media-icon">
        <Link to={`https://wa.me/${siteConfig.contact_number}?text=Hi, we have visited your website.`} target="_blank">
          <FaWhatsappSquare className="social-icon" title="What'sapp" />
        </Link>

        <Link to={`tel:${siteConfig.contact_number}`} target="_blank">
          <FaPhoneSquareAlt className="social-icon" title='Phone' />
        </Link>

        <Link to={`mailto:${siteConfig.email}`} target="_blank">
          <MdEmail className="social-icon" title="Email" />
        </Link>
        <Link to={`${siteConfig.instagram}`} target="_blank">
          <FaInstagram className="social-icon" title="Instagram" />
        </Link>
      </div>

      <div className="payment-section" title="Payment model" onClick={handleShow}>
        <img src={payLogo} className="payment-logo" />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Information </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-container">
            <div className="payment-details">
              <h4 className="mb-3">Account Details</h4>

              <p><strong>Account Holder Name :</strong> {accountDetails.account_holder_name ? accountDetails.account_holder_name : "  "}</p>
              <p><strong>Payment Number  :</strong> {accountDetails.number ? accountDetails.number : "  "}</p>

              <h6><strong>UPI ID:</strong> {accountDetails.upi_id ? accountDetails.upi_id : " "}</h6>

              <QRCode value={`upi://pay?pa=${accountDetails.upi_id ? accountDetails.upi_id : ""}`} size={200} />

            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!--Footer section end --> */}
    </>
  );
};

export default Footer;
