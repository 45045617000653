import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import Swal from "sweetalert2";
import profile from "../../../assets/images/forget-pas.jpg";
// import { ApiData } from "../CommonFile/ContextApi/Context";

const Forget = () => {
    // const { domainName } = ApiData();
    // let minnum = 4;
    // let maxnum = 20;

    // const [viewPassword, setViewPassword] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        // password: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };
    // const passwordViewHandler = () => {
    //     setViewPassword(!viewPassword);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // try {
        //     const res = await axios.post(
        //         `${domainName}/forgot-your-password`,
        //         formData
        //     );
        //     const data = res.data;
        //     console.log(data);

        //     if (res.status === 200) {
        //         const Toast = Swal.mixin({
        //             toast: true,
        //             position: "top-end",
        //             showConfirmButton: false,
        //             timer: 3000,
        //             timerProgressBar: true,
        //             didOpen: (toast) => {
        //                 toast.onmouseenter = Swal.stopTimer;
        //                 toast.onmouseleave = Swal.resumeTimer;
        //             },
        //         });
        //         Toast.fire({
        //             icon: "success",
        //             title: res.data.message,
        //         });
        //         // navigate("/login")
        //     } else {
        //         alert("data not match")
        //     }

        // } catch (error) {
        //     const Toast = Swal.mixin({
        //         toast: true,
        //         position: "top-end",
        //         showConfirmButton: false,
        //         timer: 3000,
        //         timerProgressBar: true,
        //         didOpen: (toast) => {
        //             toast.onmouseenter = Swal.stopTimer;
        //             toast.onmouseleave = Swal.resumeTimer;
        //         },
        //     });
        //     Toast.fire({
        //         icon: "error",
        //         title: "Data Not Match",
        //     });
        //     console.log("axios Error", error);
        // }
    };

    return (
        <>
            <div className="container">
                <section className="login-form shadow ">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 rounded ">
                            <div className="login-heading">
                                <h5>Forget Here </h5>
                            </div>
                            <h6 className="my-3 welcome-msg">
                                Welcome for visiting forget page {" "}
                            </h6>

                            <form className="form-data" onSubmit={handleSubmit}>
                                <div className="email-field form-group">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        placeholder=" Enter Your Email "
                                        name="email"
                                        className="form-control"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>


                                <div className="login-btn">
                                    <input
                                        type="submit"
                                        value="Forget password"
                                        className="btn btn-primary mt-4 "
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-4 align-content-center ">
                            <h4 className="p-5 or-text">Or</h4>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                            <div className="">
                                <img
                                    src={profile}
                                    alt="loginImage"
                                    title="login_image"
                                    className="img-fluid "
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Forget;
