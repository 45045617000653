import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <section>
            <div className='container mb-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='error-page-data'>
                            <h3> Oops!</h3>
                            <h5> <span> 404 </span> - page not Found </h5>
                            <p>The page youare looking for might have been removed had its name changedor
                                is temporaeily unavailable</p>
                            <Link to="/">
                                <button className='btn btn-primary'>Go to Homepage</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage
