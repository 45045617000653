import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SlActionRedo } from "react-icons/sl";
import axios from 'axios';
import { ApiData } from './CommonFile/ContextApi/Context';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    const { domainName } = ApiData();
    const [privacyData, setPrivacyData] = useState([]);
    try {
        useEffect(() => {
            const fetchData = async () => {
                const res = await axios.get(`${domainName}/view-privacy-policy`)
                // console.log(res.data.records);
                setPrivacyData(res.data.records);
            }
            fetchData();
        }, [domainName])

    } catch (error) {
        console.log("Axiox Error", error)

    }


    // console.log(privacyData);

    return (
        <>

            <Helmet>
                <title> {privacyData && privacyData.seo_title ? privacyData.seo_title : "Privacy Policy - Saral Legal Pedia | Your Trusted Legal Resource"}</title>

                <meta name="description" content={privacyData && privacyData.seo_description ? privacyData.seo_description : "Saral Legal Pedia's Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is our priority."} />

                <meta property="og:title" content={privacyData.seo_title || "Privacy Policy"} />

                <meta name="keywords" content={privacyData && privacyData.seo_keyword ? privacyData.seo_keyword : "Saral legal pedia,Legal guides,Easy legal advice,Legal advice platform,Legal information website,Legal resources for beginners,Privacy policy services,FAQs,   "} />

                <link rel="canonical" href={privacyData && privacyData.canonical_links ? privacyData.canonical_links : "www.sarallegalpedia.com/privacy-policy"} />
            </Helmet>


            {/* Bradcrum section start */}
            <div className="container-fluid p-0 portfolio-bradcrum">
                <div className="Contact_main_heading">
                    <div className="contact_head">
                        <h3> Privacy-Policy</h3>
                        <div className="bread">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/privacy-policy">Privacy-Policy</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bradcrum section End */}

            <section className='privacy-policy-section'>
                <div className='container'>

                    <div className='row '>
                        <div className='col-lg-12'>

                            <div className='privacy-policy-content'>
                                <ul>
                                    {
                                        privacyData && privacyData.length > 0 ?
                                            privacyData?.map((item) => (

                                                <li key={item.id}> <span><SlActionRedo className='fs-6' /> </span><span dangerouslySetInnerHTML={{ __html: item.privacy_policy }} />
                                                </li>
                                            ))
                                            : " "
                                    }
                                    {/* ............................................ */}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </section >

        </>
    )
}

export default PrivacyPolicy
