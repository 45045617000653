import React, { useEffect, useState } from "react";
import profile from "../../../../assets/images/background.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import { FaCity, FaGlobe, FaMapMarkedAlt, FaMobileAlt, FaRegAddressBook, FaRegUser } from "react-icons/fa";
import { LuClipboardType } from "react-icons/lu";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { FaRegFileZipper } from "react-icons/fa6";
import { ApiData } from "../../../Pages/CommonFile/ContextApi/Context";

const ServiseUserDashboard = () => {

  const { domainName } = ApiData();
  const [showModel, setShowModel] = useState(false);
  const [userData, setUserData] = useState([]);
  const [updateData, setUpdateData] = useState({
    image: null,
    address_proof: null,
    identity_proof: null,
    name: "",
    email: "",
    mobile: "",
    user_role: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
  });

  // console.log("formdata", updateData);

  const id = localStorage.getItem("id");


  const handleform = () => {
    setShowModel(!showModel);
  };


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${domainName}/view-single-user/${id}`
      );
      const data = res.data.records;
      // console.log(data);
      setUserData(data);
      setUpdateData(data);
    };
    fetchData();
  }, [domainName, id, showModel]);

  const handelChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      setUpdateData({ ...updateData, [name]: e.target.files[0] });
    } else {
      setUpdateData({ ...updateData, [name]: value });
    }
  };


  // console.log("Profile updateData", updateData)

  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", updateData.image);
      formData.append("address_proof", updateData.address_proof);
      formData.append("identity_proof", updateData.identity_proof);
      formData.append("name", updateData.name);
      formData.append("email", updateData.email);
      formData.append("mobile", updateData.mobile);
      formData.append("user_role", updateData.user_role);
      formData.append("address", updateData.address);
      formData.append("city", updateData.city);
      formData.append("state", updateData.state);
      formData.append("country", updateData.country);
      formData.append("zip_code", updateData.zip_code);

      const res = await axios.post(
        `${domainName}/user-profile-update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log(res);

      handleform();

      if (res.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: res.data.message,
        });
      }
    } catch (error) {
      console.error(error);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "success",
        title: error.response.data.message,
      });

    }
  };

  return (
    <>
      <section className="dashboard-content">
        <div className="container">
          <div className="row py-2 px-2">
            <div className="col-lg-4">
              <h4>
                Welcome <span className="text-capitalize">{userData.name}</span>
              </h4>
            </div>

            <div className="col-lg-12 user-data">
              <div className="card">
                {/* Model  open btn  */}
                <div className="update-btn">
                  <button
                    title="update"
                    type="button"
                    className="outline-0 border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    <FaUserEdit className="fs-4" />
                  </button>
                </div>
                {/* Model btn */}

                {/* Model Start */}
                <div
                  className={`modal fade ${showModel ? true : ""} `}
                  id="exampleModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Update Profile
                        </h5>
                        <button
                          type="button"
                          className="btn btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true"></span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <form
                                className="profile-formdata"
                                onSubmit={handelSubmit} encType="multiply">
                                <div className="row">
                                  <div className="col-12">
                                    <h5>Update Your Profile</h5>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <label className="form-group"> Profile Image</label>
                                  <input
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handelChange}
                                    className="form-control outline-0 border-0"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">Address Proof</label>
                                  <input
                                    type="file"
                                    name="address_proof"
                                    accept="image/*"
                                    onChange={handelChange}
                                    className="form-control outline-0 border-0"
                                  />
                                </div>

                                <div className="col-12">
                                  <label className="form-group">Identity Proof</label>
                                  <input
                                    type="file"
                                    name="identity_proof"
                                    accept="image/*"
                                    onChange={handelChange}
                                    className="form-control outline-0 border-0"
                                  />
                                </div>


                                <div className="col-12">
                                  {/* <label className="form-group">Name</label> */}
                                  <input
                                    type="hidden"
                                    name="name"
                                    value={updateData.name}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                    readOnly
                                    title="read only"
                                  />
                                </div>
                                <div className="col-12">
                                  {/* <label className="form-group">Email</label> */}
                                  <input
                                    type="hidden"
                                    name="email"
                                    value={updateData.email}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                    readOnly
                                    title="read only"
                                  />
                                </div>
                                <div className="col-12">
                                  {/* <label className="form-group">user Role</label> */}
                                  <input
                                    type="hidden"
                                    name="user_role"
                                    value={updateData.user_role}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                    readOnly
                                    title="read only"
                                  />
                                </div>
                                <div className="col-12">
                                  {/* <label className="form-group">Mobile</label> */}
                                  <input
                                    type="hidden"
                                    name="mobile"
                                    value={updateData.mobile}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                    readOnly
                                    title="read only"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">Address</label>
                                  <input
                                    type="text"
                                    name="address"
                                    value={updateData.address}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">City</label>
                                  <input
                                    type="text"
                                    name="city"
                                    value={updateData.city}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">State</label>
                                  <input
                                    type="text"
                                    name="state"
                                    value={updateData.state}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">Country</label>
                                  <input
                                    type="text"
                                    name="country"
                                    value={updateData.country}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                  />
                                </div>
                                <div className="col-12">
                                  <label className="form-group">ZipCode</label>
                                  <input
                                    type="text"
                                    name="zip_code"
                                    value={updateData.zip_code}
                                    onChange={handelChange}
                                    className="form-control shadow"
                                  />
                                </div>

                                <button
                                  className="btn btn-primary"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  value="submit"
                                  name="submit"
                                  type="submit">
                                  Profile update
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Model End */}
                <div className="card-body">
                  <div className="card-title mb-3">
                    <h5 className="text-decoration-underline"> User Info : - </h5>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center align-items-center">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="profile-img">
                            <img
                              src={userData.image ? userData.image : profile}
                              alt="images"
                              title={userData.name}
                              className="img-fluid"
                            /> 
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex flex-column align-items-center">
                          <div className="document-image">
                            <img
                              src={userData.address_proof ? userData.address_proof : ""}
                              alt="images"
                              title="Address Proof"
                              className="img-fluid"
                            />
                          </div>
                          <h6 className=" mt-2 text-center">Address Proof</h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column align-items-center">
                          <div className="document-image ">
                            <img
                              src={userData.identity_proof ? userData.identity_proof : ""}
                              alt="images"
                              title="Identity Proof"
                              className="img-fluid"
                            />
                          </div>
                          <h6 className="mt-2 text-center">Identity Proof</h6>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6">

                      <div className="py-2">
                        <p>
                          <FaRegUser />
                          Name : <span className="px-2"> {userData.name}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <MdOutlineMarkEmailRead />
                          Email :
                          <span className="px-1"> {userData.email}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <LuClipboardType />
                          User Role  :
                          <span className="px-1"> {userData.user_role}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaMobileAlt />
                          Contact :{" "}
                          <span className="px-2"> {userData.mobile}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaRegAddressBook />
                          Address : <span className="px-2"> {userData.address && userData.address !== null ? userData.address : " ********** "}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaCity />
                          City : <span className="px-2"> {userData.city && userData !== null ? userData.city : " ********** "}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaGlobe />
                          Country : <span className="px-2"> {userData.country && userData.country !== null ? userData.country : " ********** "}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaMapMarkedAlt />
                          State : <span className="px-2"> {userData.state && userData.state !== null ? userData.state : " ********** "}</span>
                        </p></div>
                      <div className="py-2">
                        <p>
                          <FaRegFileZipper />
                          ZipCode : <span className="px-2"> {userData.zip_code && userData.zip_code !== null ? userData.zip_code : " ********** "}</span>
                        </p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default ServiseUserDashboard;
