import React, { useState } from 'react'
import axios from 'axios';
import { ApiData } from './ContextApi/Context';
import { FaStar } from 'react-icons/fa';
import Swal from 'sweetalert2';

const AddUserReview = ({ handleShow }) => {
    const { domainName } = ApiData()
    const [rating, setRating] = useState(0);
    const [reviewdata, setReviewData] = useState({
        rating: rating || "",
        name: "",
        review: "",
    })


    const handelChange = (e) => {
        const { name, value } = e.target;
        setReviewData({
            ...reviewdata, [name]: value
        })

    }
    const handelStarClick = (starValue) => {
        setRating(starValue);
        setReviewData({
            ...reviewdata,
            rating: starValue
        })

    }

    console.log("reviewData", reviewdata);

    const handelSubmit = async (e) => {
        e.preventDefault();
        try {

            const res = await axios.post(`${domainName}/add-review`, reviewdata);

            console.log(res);

            if (res.status === 200) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: res.data.message,
                });

                handleShow();

                setReviewData({
                    name: "",
                    rating: 0,
                    review: "",
                })
            }
        } catch (error) {
            console.error("Axios Error", error);
            handleShow();
            setReviewData({
                name: "",
                rating: "",
                review: "",
            })

        }
    }
    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={handelSubmit}>
                        <div className="form-data mb-4">
                            <label className="form-group my-2">Name</label>
                            <input className="form-control" type="text" name="name" placeholder="Your Name" autoComplete="off" onChange={handelChange} value={reviewdata.name} />
                        </div>
                        <div className="form-data mb-4">
                            <label className="form-group">Rating </label>
                            <div className="stars my-3">
                                <ul className="d-flex justify-content-evenly ">
                                    {
                                        [1, 2, 3, 4, 5].map((item) => {
                                            return <li className={`${item <= rating ? 'yellow' : ''}`} value={reviewdata.rating} key={item} onClick={() => handelStarClick(item)} ><FaStar className="fs-2" /></li>
                                        })
                                    }
                                </ul>
                            </div>

                        </div>
                        <div className="form-data mb-4">
                            <label className="form-group my-2">Review </label>
                            <input className="form-control" type="text" name="review" placeholder=" Write Your Reviews" autoComplete="off" onChange={handelChange} value={reviewdata.review} />
                        </div>

                        <div className="d-flex justify-content-end">
                            <input type="submit" value="Submit" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUserReview