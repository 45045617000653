import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Timg from "../../../assets/images/avtar.jpg"
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from "react-icons/bi";
import axios from 'axios';
import Star from '../Star';
import { ApiData } from '../CommonFile/ContextApi/Context';
const Testimonial = () => {
    const { domainName } = ApiData();
    const [testimoniialData, setTeasimonialData] = useState([]);


    try {
        useEffect(() => {
            const fetchData = async () => {
                const res = await axios.get(`${domainName}/get-approved-testimonial`)
                // console.log(res.data);
                setTeasimonialData(res.data);
            }

            fetchData()
        }, [domainName])


    } catch (error) {
        console.log("Axios Error", error)

    }

    const owlOptions = {
        autoplay: true,
        rewind: true,
        transition: 400,
        margin: 20,
        dots: false,
        responsiveClass: true,
        autoplayTimeout: 6000,
        smartSpeed: 3000,
        nav: true,
        navText: ["<", ">"],
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 3 },
            1366: { items: 3 },
        },
    };




    return (
        <>
            <section className='testimonial-section my-3 mt-5'>
                <div className='container'>
                    {
                        testimoniialData && testimoniialData.length > 0
                            ?
                            <div className='heading-section'>
                                <h3 className='m-0 '>Testimonials  </h3>
                            </div> :
                            " "

                    }
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>

                            {
                                testimoniialData && testimoniialData.length > 0
                                    ?
                                    <OwlCarousel className='owl-theme' {...owlOptions}>

                                        {testimoniialData.slice(0, 5).map((item) => (

                                            <div className='item' key={item.id}>
                                                <div className='card'>
                                                    <div className='upper-card-contant d-flex align-items-center'>
                                                        <div className='card-image'>
                                                            <img className='img-fluid' src={item.image ? item.image : Timg} alt={item.name} title={item.name} />

                                                        </div>
                                                        <div className='mx-4'>
                                                            <h6>{item.name}</h6>
                                                            {/* Show Start  */}
                                                            <Star rating={item.rating} />
                                                        </div>
                                                    </div>
                                                    <div className='card-description'>
                                                        <p>
                                                            <span ><sup><BiSolidQuoteAltLeft /></sup></span>
                                                            {item.testimonial}
                                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
                                                            <span ><sub><BiSolidQuoteAltRight /></sub></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )).reverse()
                                        }

                                    </OwlCarousel>
                                    :
                                    " "
                                // <p className='text-center text-danger fs-5 fw-semibold my-2'>No Data Found !! </p>
                            }

                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default Testimonial
