import React from 'react'
import { FaUserCheck } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const Topbar = () => {

    const navigate = useNavigate();
    
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("id");
        localStorage.removeItem("provider_id");
        localStorage.removeItem("user_role");
        localStorage.removeItem("user_type");
        localStorage.removeItem("showDetails");

        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            title: "Logout successfully",
        });

        // setLogin(false);
        navigate("/login");

    };
    return (
        <div className='topbar-section '>
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-lg-12 ">
                        <div className="dropdown float-end login-box">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <FaUserCheck className="fs-3 " />
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <Link to="/" className="text-decoration-none">

                                    <button className="dropdown-item " type="button" >Home</button>
                                </Link>
                                <Link to="/" className="text-decoration-none">
                                    <button className="dropdown-item " type="button" onClick={handleLogout}>Logout</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar
