import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ApiData } from '../CommonFile/ContextApi/Context';
import { Link } from 'react-router-dom';

const HomeVideos = () => {
    const { domainName } = ApiData();
    const [videoData, setVideoData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            const res = await axios.get(`${domainName}/video-view`);

            setVideoData(res.data.records);

        }
        fetchData();
    }, [domainName])
    return (
        <section className="video-section my-3">
            <div className="container">

                {
                    videoData && videoData.length > 0 ?
                        <div className='heading-section'>
                            <h3 className='m-0 '> Videos </h3>
                        </div> :
                        " "
                }
                <div className="row">
                    {
                        videoData && videoData.length > 0 ?
                            videoData.slice(0, 6).map((item) => (
                                <div className="col-lg-4 col-md-6 col-12" key={item.id}>
                                    <div className="main-video-section">
                                        <video controls>
                                            <source src={item.video} type="video/mp4" title={item.title} />
                                        </video>
                                    </div>
                                </div>
                            ))

                            :
                            ""
                    }


                    {
                        videoData && videoData.length > 5 ?

                            <div className='col-12 d-flex justify-content-end'>
                                <Link to="/gallery">
                                    <button className="all-btn">View More </button>
                                </Link>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        </section>
    )
}

export default HomeVideos