import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { ApiData } from "../CommonFile/ContextApi/Context";

const HomeGallery = () => {
    const { domainName } = ApiData();
    const [galleryData, setGalleryData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${domainName}/gallery-view`);
                const data = res.data.records;
                setGalleryData(data);
            } catch (error) {
                console.log("axios gallery error: ", error)
            }
        };
        fetchData();
    }, [domainName]);


    return (
        <section className='gallery_section_main_wrapper  my-3 mt-5'>
            <div className='container'>
                {
                    galleryData && galleryData.length > 0
                        ?
                        <div className='heading-section'>
                            <h3 className='m-0 '>Gallery  </h3>
                        </div> :
                        " "
                }
                <div className="row">
                    {
                        galleryData && galleryData.length > 0 ?
                            galleryData.slice(0, 12).map((item) => {
                                return (

                                    <div className="col-lg-3 col-md-4 col-6 " key={item.id}>
                                        <div className="gallery_img shadow">
                                            <img
                                                className="img-fluid"
                                                src={item.gallery_image}
                                                alt={item.title}
                                                title={item.title}
                                            />
                                        </div>
                                    </div>

                                );
                            }).reverse()

                            : " "
                    }
                </div>
                {
                    galleryData && galleryData.length >= 12
                        ?
                        <div div className="col-12 d-flex justify-content-center  my-4">
                            <Link to="/gallery">
                                <button className="all-btn"> View More </button>
                            </Link>
                        </div>
                        :
                        ""
                }
            </div>
        </section >
    )
}

export default HomeGallery