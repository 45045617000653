import React from 'react'
import { FaStar } from 'react-icons/fa';
import { AiOutlineStar } from "react-icons/ai";

const Star = ({ rating }) => {
    // console.log(rating);
    const ratingStar = Array.from({ length: 5 }, (elem, index) => {
        // let number = index + 0.5;

        return (
            <span key={index}>
                {rating >= index + 1 ? (
                    <FaStar className='text-warning fs-5' />

                ) : (
                    <AiOutlineStar className="text-warning fs-5" />
                )}
            </span>
        )
    })
    return (
        <section>
            <div className='reating-star'>
                {ratingStar}
            </div>
        </section>
    )
}

export default Star
