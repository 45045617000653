import React from 'react'
import { ApiData } from '../CommonFile/ContextApi/Context'

const MainHeading = () => {

    const { siteConfig } = ApiData();
    return (

        <section className='main-heading-section'>
            <marquee behavior='scroll' direction="left" onMouseOver={(e) => { e.target.stop() }} onMouseOut={(e) => { e.target.start() }} >

                {
                    siteConfig && siteConfig !== "" ?

                        siteConfig.home_sliding_text
                        :

                        <p className='text-center text-danger fw-semibold '>No Data Found... !! </p>
                }

            </marquee>
        </section>


    )
}

export default MainHeading
