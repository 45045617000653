import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ApiData } from '../CommonFile/ContextApi/Context';
import Star from '../Star';
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const UserReview = () => {
    const { domainName } = ApiData()
    const [viewReview, setViewReview] = useState([]);

    useEffect(() => {
        try {
            const fetchReview = async () => {

                const res = await axios.get(`${domainName}/get-approved-review`)
                // console.log(res);

                if (res.status === 200) {
                    setViewReview(res.data)
                }
            }

            fetchReview()
        } catch (error) {
            console.log("Axios Error", error);

        }
    }, [domainName])
    // console.log(viewReview);


    const owlOptions = {
        autoplay: true,
        rewind: true,
        transition: 400,
        margin: 20,
        dots: false,
        responsiveClass: true,
        autoplayTimeout: 6000,
        smartSpeed: 3000,
        nav: true,
        navText: ["<", ">"],
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 3 },
            1366: { items: 3 },
        },
    };


    return (
        <section className='user-review my-3 mt-5'>
            <div className='container'>
                {
                    viewReview && viewReview.length > 0
                        ?
                        <div className='heading-section'>
                            <h3 className='m-0 '>User Review  </h3>
                        </div> :
                        " "

                }
                <div className="row">
                    <div className='col-lg-12 col-md-12'>

                        {
                            viewReview && viewReview.length > 0
                                ?
                                <OwlCarousel className='owl-theme' {...owlOptions}>

                                    {viewReview.slice(0, 5).map((item) => (

                                        <div className='user-review-card  text-white p-4 rounded-2 shadow' key={item.id} title={item.name}>

                                            <div className='d-flex justify-content-between'>

                                                <h6 className='fs-5 fw-semibold' title={item.name} >{item.name}</h6>

                                                {/* Show Start  */}
                                                <div >
                                                    <Star rating={item.rating} />
                                                </div>

                                            </div>
                                            <div className='user-review-card-description'>
                                                <p>
                                                    <span ><sup><BiSolidQuoteAltLeft className="text-info me-2" /></sup></span>
                                                    {item.review}
                                                    <span ><sub><BiSolidQuoteAltRight className='text-primary ms-2' /></sub></span>
                                                </p>
                                            </div>
                                        </div>
                                    )).reverse()
                                    }

                                </OwlCarousel>
                                :
                                " "
                            // <p className='text-center text-danger fs-5 fw-semibold my-2'>No Data Found !! </p>
                        }

                    </div>

                </div>
            </div>
        </section>
    )
}

export default UserReview