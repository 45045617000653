import axios from 'axios';
import React, { useState } from 'react'
import { FaStar } from 'react-icons/fa'
import { TiArrowBack } from 'react-icons/ti';
import Swal from 'sweetalert2';
import { ApiData } from '../../../Pages/CommonFile/ContextApi/Context';

const SpEditTestimonial = ({ testimonialData, handelEdit, id }) => {
    const { domainName } = ApiData();

    const [updateStar, setUpdateStar] = useState();

    const [updaTetestimonialData, setUpdateTestimonialData] = useState({
        rating: testimonialData.rating || "",
        testimonial: testimonialData.testimonial || ""
    });


    const handelChange = (e) => {
        const { name, value } = e.target;
        setUpdateTestimonialData({ ...updaTetestimonialData, [name]: value });

    }

    // console.log("updated data", updaTetestimonialData);

    const handelUpdate = async (e) => {

        e.preventDefault();
        try {
            // console.log("update data", testimonialData.id)

            const testimonialFormData = new FormData();

            testimonialFormData.append("rating", updaTetestimonialData.rating)
            testimonialFormData.append("testimonial", updaTetestimonialData.testimonial)

            const res = await axios.post(`${domainName}/update-testimonial/${id}`,
                testimonialFormData)


            // console.log("data sent  sccessfully ", res);

            handelEdit();

            if (res.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: "Record has been updated",
                });
            }
        } catch (error) {
            console.log(error);

        }
    }

    const handleStarClick = (val) => {
        console.log("StarValue", val)
        setUpdateStar(val);
        setUpdateTestimonialData({
            ...updaTetestimonialData, rating: val,

        });
    }
    return (
        <>

            <div className="dashboard--content px-3">

                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-12 col-12'>
                            <div className='button'>
                                <button className='btn btn-info float-end me-5' onClick={handelEdit}>
                                    <TiArrowBack />back
                                </button>
                            </div>
                        </div>
                        <h4 className='text-center'> Edit Your Testimonials </h4>

                        <div className='col-12 mt-4'>
                            <form className='feedback-form px-1 shadow' onSubmit={handelUpdate} >
                                <h4 className='text-center'> Your Rating </h4>
                                <div className='star-rating'>
                                    <ul className='d-flex'>
                                        {[1, 2, 3, 4, 5].map((elem) => (
                                            <li
                                                key={elem}
                                                className={`star fs-2 ${elem <= updateStar ? 'yellow' : ''}`}

                                                value={updaTetestimonialData.rating}
                                                onClick={() => handleStarClick(elem)}
                                                onChange={handelChange}
                                            >
                                                <FaStar />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='row px-5'>

                                    <div className='col-lg-12 col-12 mt-3'>
                                        <label className='form-group fs-5 my-3'>Testiminoal </label>
                                        <textarea
                                            placeholder='Your testimonial '
                                            name="testimonial"
                                            type="text"
                                            className="form-control"
                                            rows="10"
                                            cols="12"
                                            value={updaTetestimonialData.testimonial}
                                            onChange={handelChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='mt-3 float-end m-4 pb-5 '>
                                    <button type="submit" value="submit" className='all-btn'>Update Testimonial </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpEditTestimonial
