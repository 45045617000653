import React, { useEffect, useState } from 'react'
import { TiArrowBack } from "react-icons/ti";
import taskImg from "../../../../assets/images/viewtask.png"
import axios from 'axios';
import { ApiData } from '../../../Pages/CommonFile/ContextApi/Context';

const ViewAssignTask = ({ handelView, id }) => {
    const { domainName } = ApiData();
    const [viewData, setViewData] = useState([]);

    const userId = localStorage.getItem("id");
    useEffect(() => {

        const fetchData = async () => {
            const res = await axios.get(
                `${domainName}/get-single-task/${id}/${userId}`);

            console.log("singleData", res);
            const data = res.data.result;
            setViewData(data)


        }
        fetchData();
    }, [domainName, userId, id])
    return (
        <section className='task-view-section'>
            <div className="container">
                <div className="row">
                    <div className='col-12 d-flex justify-content-end my-3'>
                        <button className="btn btn-info" onClick={() => handelView()}>
                            <TiArrowBack /> Back
                        </button>
                    </div>
                    <div className='col-6'>
                        {
                            viewData && viewData.length !== " " ?

                                <div className="view-assign-task shadow p-3 m-3 rounded-3">
                                    <div className='view-task-heading'> <h4> View Assign Task Details : -  </h4> </div>
                                    <h6 className='my-2 text-capitalize text-muted'> Task Title: <span className='text-dark'> {viewData.task_title}</span></h6>
                                    <p className='text-muted my-3 '>Amount : <span className='text-dark fs-6'> {viewData.amount}</span></p>
                                    <p className='text-muted my-3 '>Payment Status : <span className='text-dark fs-6'> {viewData.payment_status}</span></p>
                                    <p className='text-muted my-3 '>Task Status : <span className='text-dark fs-6'> {viewData.task_status}</span></p>
                                    <p className='text-muted my-3 '>Task End Date : <span className='text-dark fs-6'> {viewData.task_end_date}</span></p>
                                    <p className='text-muted my-3  d-flex gap-2'>Description : <span className='text-dark fs-6' dangerouslySetInnerHTML={{ __html: viewData.task_description }} /></p>

                                </div>

                                :
                                <p className='fs-5 fw-semibold text-center text-danger'> No Data Found !!</p>
                        }

                    </div>
                    <div className='col-6'>
                        <div className='task-view-image m-2'>
                            <img src={taskImg} alt='Task pic' title='task-pic' className='rounded-3' />
                        </div>
                    </div>
                </div>
            </div >
        </section>
    )
}

export default ViewAssignTask