import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiData } from '../CommonFile/ContextApi/Context';
import { FaPlus } from "react-icons/fa";
import { Link } from 'react-router-dom';

const TotalUser = () => {
    const { siteConfig, domainName } = ApiData();
    const [enquiry, setEnquiry] = useState("")

    useEffect(() => {

        const fetchData = async () => {
            try {
                const totalEnquiry = await axios.get(`${domainName}/total-service-enquiry`)

                setEnquiry(totalEnquiry.data.message)

            } catch (error) {
                console.log("Axios Error", error)

            }

        }
        fetchData();

    }, [domainName])


    useEffect(() => {
        setEnquiry(enquiry);
    }, [enquiry])

    // console.log("Enquery ", enquiry);
    // console.log("SiteConfig ", siteConfig);
    return (
        <>
            <section className='total-user-section'>
                <div className='container-fluid'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='row website-name-heading align-items-center'>

                                <div className='col-lg-3 col-md-6 col-12'>
                                    <p className='p-2 m-1 rounded-2  top-heading-animate-one text-center '>Saral Legal Pedia</p>
                                </div>

                                <div className='col-lg-6 col-md-6 col-12'>
                                    <Link to={`mailto:${siteConfig.email}`} target="_blank" className="text-decoration-none">
                                        <span className='mx-2 text-danger'>{siteConfig.email} </span>
                                    </Link>
                                    <Link to={`tel:${siteConfig.contact_number}`} target="_blank" className="text-decoration-none">
                                        <span className='mx-2 text-danger'>{siteConfig.contact_number} </span>
                                    </Link>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='row justify-content-end' >
                                <div className='col-lg-4 col-6 p-0'>
                                    <div className='total-count-section'>
                                        <span className='new-blink ms-4'>New</span>
                                        <em className='ms-2'>
                                            Total Enquiries
                                        </em>
                                        <p className='top-heading-animate'>
                                            <span className='mt-1'>
                                                {enquiry}
                                            </span>
                                            <span>
                                                <FaPlus />
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-6 '>
                                    <div className='total-count-section'>
                                        <span className='new-blink ms-4'>New</span>
                                        <em className='ms-2'>
                                            Total Visiters
                                        </em>
                                        <p className='top-heading-animate'>
                                            <span className='mt-1'>
                                                {/* {"0"} */}

                                                <img src="https://hitwebcounter.com/counter/counter.php?page=15319123&style=0006&nbdigits=1&type=ip&initCount=0" title="Counter Widget" alt="Visit counter For Websites" />
                                            </span>
                                            <span>
                                                <FaPlus />
                                            </span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default TotalUser;
