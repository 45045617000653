import React, { useEffect } from "react";
import Sidebar from "../../Dashboards/Service-Provider-Dashboard/CommanFile/Sidebar";
import SidebarFooter from "../../Dashboards/Service-Provider-Dashboard/CommanFile/SidebarFooter";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SpLayout = () => {

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {

    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "User Unauthorizede",
        text: "Please Login first to continue"
      });
      return navigate('/login');

    }
  }, [token, navigate])


  return (
    <div>
      <Sidebar />
      <SidebarFooter />
    </div>
  );
};

export default SpLayout;
