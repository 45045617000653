import React, { useEffect, useState } from "react";
import Herosection from "./Herosection";
import Testimonial from "./Testimonial";
import FaqSection from "./Faqs/FaqSection";
import LatestUpdate from "./LatestUpdate";
import HomeGallery from "./HomeGallery";
import WhyChooseUs from "./WhyChooseUs";
import { Helmet } from "react-helmet";
import { ApiData } from "../CommonFile/ContextApi/Context";
import HomeVideos from "./HomeVideos";
import UserReview from "./UserReview";

const Home = () => {
  const { siteConfig } = ApiData();
  const [Ismodal, setIsModal] = useState(false);

  useEffect(() => {
    const hasShownModal = sessionStorage.getItem("hasShownModal");

    if (!hasShownModal) {
      setIsModal(true);
      sessionStorage.setItem("hasShownModal", "true");
    }
  }, []);

  const handleCloseModal = () => {
    setIsModal(false);
  };

  return (
    <>
      <Helmet>
        <title> {siteConfig && siteConfig.seo_title ? siteConfig.seo_title : "Saral Legal Pedia | Comprehensive Legal Information & Resources"}</title>

        <meta name="description" content={siteConfig && siteConfig.seo_description ? siteConfig.seo_description : "Welcome to Saral Legal Pedia, your trusted source for comprehensive legal information and expert guidance. Discover valuable legal resources, updates, and advice tailored to meet your needs. Explore our website to access reliable legal insights and connect with our knowledgeable team."

        } />

        <meta property="og:title" content={siteConfig.seo_title || "Home Page"} />

        <meta name="keywords" content={siteConfig && siteConfig.seo_keyword ? siteConfig.seo_keyword : "Saral legal pedia,Legal guides,Easy legal advice,Legal advice platform,Legal information website,Legal resources for beginners, services,FAQs,   "} />
        <meta name="author" content={"Frontend:- Akash Lowanshi (SDMIPL), Backend:- Udit Chouhan (SDMIPL)"} />
        <meta name="publisher" content={"Sion Datamatic India PVT LTD"} />

        <link rel="canonical" href={siteConfig && siteConfig.canonical_links ? siteConfig.canonical_links : "https://sarallegalpedia.com/"} />
      </Helmet>

      <Herosection />
      {Ismodal &&
        <LatestUpdate ShowModal={Ismodal} CloseModal={handleCloseModal} />
      }
      <WhyChooseUs />
      <HomeGallery />
      <HomeVideos />
      <Testimonial />
      <UserReview />
      <FaqSection />
    </>
  );
};

export default Home;
