import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import axios from "axios";
import { ApiData } from "./ContextApi/Context";
import { FaUserCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import SecondLoading from "../CommonFile/SecondLoading"
import Swal from "sweetalert2";

const Header = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const { siteConfigData, updateAlertData, faqSectionData, domainName } = ApiData();
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [data, setData] = useState({
    userData: [],
    servicesData: [],
    subservices: [],
    siteConfig: {},
    updateAlert: {},
    FaqSection: [],
  })

  useEffect(() => {
    const showCard = async () => {
      if (
        accessToken !== null &&
        accessToken !== "" &&
        accessToken !== undefined
      ) {
        setLogin(true);
      } else {
        setLogin(false);
      }

      try {

        const [userDataRes, servicesRes, siteConfigRes, updateAlertRes, FaqSectionRes] = await Promise.all([
          axios.get(`${domainName}/user-view`),
          axios.get(`${domainName}/get-service-subservice`),
          axios.get(`${domainName}/get-site-config`),
          axios.get(`${domainName}/get-update-alerts`),
          axios.get(`${domainName}/get-faqs`),
        ])
        setData({
          userData: userDataRes.data.records,
          servicesData: servicesRes.data,
          siteConfig: siteConfigRes.data.records,
          updateAlert: updateAlertRes.data.records,
          FaqSection: FaqSectionRes.data.records,
        })
        setLoading(false)
        siteConfigData(siteConfigRes.data.records);
        updateAlertData(updateAlertRes.data.records);
        faqSectionData(FaqSectionRes.data.records);

      } catch (error) {
        console.log(error)
      }
    };

    showCard()
  }, [login, accessToken, domainName]);

  const handlelogin = () => {
    navigate("/login");
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    setIsCollapsed(!isCollapsed)
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("id");
    localStorage.removeItem("provider_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("user_type");
    localStorage.removeItem("showDetails");


    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Logout successfully",
    });
    setLogin(false)
    navigate("/login");

  };

  if (loading) {
    return (
      <div className='container'>
        <div className='row p-5 mt-10'>
          {/* <Loading /> */}
          <SecondLoading />
        </div>
      </div>
    );
  }


  return (
    <>

      {/* <!-- Navbar Section Start--> */}
      <header>
        <section className="navbar_section_main_wrapper">
          <div className="header_menu">
            <nav className="navbar navbar-expand-lg ">
              <div className="container-fluid">
                <Link className="navbar_logo" to="/">
                  <img src={data.siteConfig.logo ? data.siteConfig.logo : " "} alt="main logo" className="img-fluid" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={!isCollapsed}
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                    {
                      isCollapsed ?

                        <FaBars onClick={() => setIsCollapsed(!isCollapsed)} />
                        :
                        <RxCross2 onClick={() => setIsCollapsed(!isCollapsed)} />
                    }

                  </span>
                </button>
                <div
                  className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}
                  id="navbarSupportedContent">
                  <ul className="navbar-nav  align-items-center mx-auto ">
                    <li className="nav-item " >
                      <NavLink to="/" className="nav-link" aria-current="page" onClick={handleToggle}>
                        Home
                      </NavLink>
                    </li>

                    <li className="nav-item" >
                      <NavLink to="/about-us" className="nav-link" onClick={handleToggle}>
                        About Us
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown" >
                      <Link
                        to=""
                        className={`nav-link d-flex justify-content-center  align-items-center gap-1 dropdown-toggle`}
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="true">
                        Service
                        <span className="caret"></span>
                      </Link>
                      <ul className="dropdown-menu">
                        {data.servicesData.map((item) => (
                          <li className="nav-item" key={item.id}>
                            <NavLink to={`/services/${item.slug}`} className="drop nav-link">
                              {item.title}
                            </NavLink>
                            {
                              item.subservices.length > 0 ?
                                <ul className="dropdown-menu  subservices-drop">
                                  {
                                    item.subservices.map((elem) => (
                                      <li className="nav-item subservices" key={elem.id}>
                                        <Link to={`/services/${item.slug}/${elem.slug}`}>{elem.title}</Link>
                                      </li>
                                    ))
                                  }
                                </ul>
                                :
                                ""
                            }
                          </li>

                        )).reverse()
                        }
                      </ul>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/gallery"
                        className="nav-link"
                        aria-current="page" onClick={handleToggle}>
                        Gallery
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/contact-us"
                        className="nav-link"
                        aria-current="page" onClick={handleToggle}>
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/enquiry-form"
                        className="nav-link"
                        aria-current="page" onClick={handleToggle}>
                        Enquire Now
                      </NavLink>
                    </li>

                  </ul>
                  {login ? (
                    <ul className="navbar-nav mr-auto mb-2 mb-lg-0 ">
                      <li className="nav-item">
                        <div className="login-box">
                          <div className="dropdown text-center">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                              <FaUserCheck className="fs-3" />
                            </button>
                            <div className="dropdown-menu after-login" aria-labelledby="dropdownMenu2">
                              <Link to="service-provider-dashboard/" className="text-decoration-none">
                                <button className="dropdown-item" type="button">Dashboard</button>
                              </Link>
                              <Link to="" className="text-decoration-none" onClick={handleLogout}>
                                <button className="dropdown-item" type="button">Logout</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="navbar-nav d-flex  justify-content-center align-items-center mr-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <div className="login-box">
                          <button className="nav-link px-2 py-2" onClick={handlelogin}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Log In
                          </button>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </section >
        {/* <!-- Navbar Section End--> */}
      </header >
    </>
  );
};

export default Header;
