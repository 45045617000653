    import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiData } from "../../../Pages/CommonFile/ContextApi/Context";
import { FaEye } from "react-icons/fa";
import ViewAssignTask from "./ViewAssignTask";
import Swal from "sweetalert2";
const SpAssinedTask = () => {

    const columns = [
        {
            label: "S No.",
            name: "id",
            options: {
                sort: false,
                customBodyRender: (value, action) =>
                    action.rowIndex + 1
            }

        },
        {
            label: "Task Title",
            name: "task_title",
            options: {
                customBodyRender: (value) => <p >{value.slice(0, 20)}</p>
            }

        },
        {
            label: "Task Description",
            name: "task_description",
            options: {
                customBodyRender: (value) => <p dangerouslySetInnerHTML={{ __html: value.slice(0, 19) }} />
            }
        },

        {
            label: "Amount",
            name: "amount"

        },
        {
            label: "Payment Status",
            name: "payment_status",
            options: {
                customBodyRender: (value) => value ? value : <p className="text-danger">No Status</p>
            }
        },
        {
            label: "Task Review",
            name: "task_review",
            options: {
                customBodyRender: (value) => <p dangerouslySetInnerHTML={{ __html: value.slice(0, 19) }} />
            }
        },
        {
            label: "Task Status",
            name: "task_status",
            options: {
                customBodyRender: (value, action) => (
                    <select className={"form-select"} value={value} onChange={(e) => handelStatusUpdate(e.target.value, action.rowData[0])}>
                        <option value="pending">Pending</option>
                        <option value="work-in-progress"> Work In Progress</option>
                        <option value="completed"> Completed</option>
                    </select>

                )
            }

        },
        {
            label: "End Date",
            name: "task_end_date",
        },
        {
            label: "Action",
            // name: "created_at",
            name: "id",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) =>
                    <div>
                        <button className="btn btn-info" title="View Task" onClick={() => handelView(value)}><FaEye /></button>
                    </div>

            }
        },
    ];

    const { domainName } = ApiData();
    const [AssignData, setAssignData] = useState([]);
    const [isView, setIsView] = useState(false);
    const [viewData, setViewData] = useState("");

    const userId = localStorage.getItem("id");


    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`${domainName}/get-task/${userId}`);
            const data = res.data.result;

            if (res.status === 200) {
                setAssignData(data);
            }
            else {
                console.log("Api Error")
            }
            // console.log("AssignData ", data)
        }
        fetchData();
    }, [domainName, userId])

    const options = {
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 20, 30, 50]
    };


    const handelView = (id) => {
        setIsView(!isView);
        setViewData(id);
    }

    // console.log("Assign Data", AssignData);

    const handelStatusUpdate = async (value, id) => {

        try {
            const res = await axios.post(`${domainName}/update-task-status/${id}/${userId}`, {
                task_status: value
            })

            setAssignData((prevData) =>
                prevData.map((task) =>
                    task.id === id ? { ...task, task_status: value } : task
                )
            );
            if (res.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    title: res.data.result,
                });
            }
        } catch (error) {
            console.log("Api Error ", error)
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                },
            });
            Toast.fire({
                icon: "error",
                title: error.response.data.result,
            });

        }

    }


    return (
        <>

            <div className="dashboard--content px-3">
                <div className="main-header mt-4">
                    <div className="header--sidebar">
                        <h3>
                            <Link to="/service-provider-dashboard/" className="header--menu">
                                <span> Home</span>
                            </Link>
                            /
                            <span>Assigned Task Table</span>
                        </h3>
                    </div>
                </div>
            </div>
            {
                isView ?
                    <ViewAssignTask handelView={handelView} id={viewData} />
                    :
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 mt-3' >
                                <MUIDataTable className=""
                                    title={"Assined Task  Table"}
                                    data={AssignData}
                                    columns={columns}
                                    options={options}
                                />

                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default SpAssinedTask