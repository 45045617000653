import React from 'react'

const SecondLoading = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="loading loading06">
                            <span data-text="S">S</span>
                            <span data-text="A">A</span>
                            <span data-text="R">R</span>
                            <span data-text="A">A</span>
                            <span data-text="L">L</span>

                            <span data-text="-">-</span>

                            <span data-text="L">L</span>
                            <span data-text="E">E</span>
                            <span data-text="G">G</span>
                            <span data-text="A">A</span>
                            <span data-text="L">L</span>

                            <span data-text="-">-</span>

                            <span data-text="P">P</span>
                            <span data-text="E">E</span>
                            <span data-text="D">D</span>
                            <span data-text="I">I</span>
                            <span data-text="A">A</span>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default SecondLoading