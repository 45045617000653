import React, { useEffect, useState } from 'react'
import { ApiData } from '../../CommonFile/ContextApi/Context';
import MyAccordian from './MyAccordian';

const FaqSection = () => {

    const { faqSection } = ApiData();
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        setFaqs(faqSection);
    }, [faqSection]);

    // console.log("faqs", faqs)


    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>

                        {

                            faqs && faqs.length > 0 ?

                                <>
                                    <div className='heading-section'>
                                        <h3>Frequently Asked Questions</h3>
                                    </div>

                                    <div>

                                        {faqs.slice(0, 5).map((item) => {
                                            const { id } = item;
                                            return <MyAccordian key={id} {...item} />
                                        }).reverse()
                                        }

                                    </div>
                                </>

                                :
                                ""
                        }

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqSection
