import "./App.css";
import "./assets/css/dashboard.css";
import "./assets/css/sidebar.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./Component/Pages/ScrollToTop";
import Home from "./Component/Pages/HomePages/Home";
import Layout from "./Component/Layout/Homepage-layout/Layout";
import ErrorPage from "./Component/Pages/ErrorPage";
import Login from "./Component/Pages/Login&Signup/Login"
import Forget from "./Component/Pages/Login&Signup/Forget"
import Signup from "./Component/Pages/Login&Signup/Signup"
import UserSignup from "./Component/Pages/Login&Signup/UserSignup"
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy";
import TermsCondition from "./Component/Pages/TermsCondition";
import About from "./Component/Pages/About";
import Gallery from "./Component/Pages/Gallery";
import Contact from "./Component/Pages/Contact";
import ServiceCompanies from "./Component/Pages/ServicesFile/ServiceCompanies";

// Service Provider Dashboard section  
import SpLayout from "./Component/Layout/ServiceProvider-Layout/SpLayout";
import ServiseProviderDashboard from "./Component/Dashboards/Service-Provider-Dashboard/Pages/ServiseProviderDashboard";
import SpViewTestimonial from "./Component/Dashboards/Service-Provider-Dashboard/Pages/SpViewTestimonial";
import SpAssinedTask from "./Component/Dashboards/Service-Provider-Dashboard/Pages/SpAssinedTask";
import SubServices from "./Component/Pages/ServicesFile/SubServices";
import EnquiryTable from "./Component/Pages/HomePages/EnquiryTable";


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<ErrorPage />} />
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<Forget />} />
            <Route path="/signup" element={< Signup />} />
            <Route path="/user-signup" element={<UserSignup />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/enquiry-form" element={<EnquiryTable />} />
            {/* Service Page  */}
            <Route path="/services/:slug" element={<ServiceCompanies />} />
            <Route path="/services/:slug/:slug" element={<SubServices />} />
          </Route>

          {/* SerpiveProvider Layout */}

          <Route path="/service-provider-dashboard" element={<SpLayout />}>
            <Route index element={<ServiseProviderDashboard />} />
            <Route
              path="/service-provider-dashboard/assign-task-table/"
              element={<SpAssinedTask />}
            />
            <Route
              path="/service-provider-dashboard/testimonial/"
              element={<SpViewTestimonial />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;